import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../common/footer";
import Header from "../common/header";

import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SummerHouse204WildDunes_1 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_1.jpg';
import SummerHouse204WildDunes_2 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_2.jpg';
import SummerHouse204WildDunes_3 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_3.jpg';
import SummerHouse204WildDunes_4 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_4.jpg';
import SummerHouse204WildDunes_5 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_5.jpg';
import SummerHouse204WildDunes_6 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_6.jpg';
import SummerHouse204WildDunes_7 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_7.jpg';
import SummerHouse204WildDunes_8 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_8.jpg';
import SummerHouse204WildDunes_9 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_9.jpg';
import SummerHouse204WildDunes_10 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_10.jpg';
import SummerHouse204WildDunes_11 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_11.jpg';
import SummerHouse204WildDunes_12 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_12.jpg';
import SummerHouse204WildDunes_13 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_13.jpg';
import SummerHouse204WildDunes_14 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_14.jpg';
import SummerHouse204WildDunes_15 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_15.jpg';
import SummerHouse204WildDunes_16 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_16.jpg';
import SummerHouse204WildDunes_17 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_17.jpg';
import SummerHouse204WildDunes_18 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_18.jpg';
import SummerHouse204WildDunes_19 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_19.jpg';
import SummerHouse204WildDunes_20 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_20.jpg';
import SummerHouse204WildDunes_21 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_21.jpg';
import SummerHouse204WildDunes_22 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_22.jpg';
import SummerHouse204WildDunes_23 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_23.jpg';
import SummerHouse204WildDunes_24 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_24.jpg';
import SummerHouse204WildDunes_25 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_25.jpg';
import SummerHouse204WildDunes_26 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_26.jpg';
import SummerHouse204WildDunes_27 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_27.jpg';
import SummerHouse204WildDunes_28 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_28.jpg';
import SummerHouse204WildDunes_29 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_29.jpg';
import SummerHouse204WildDunes_30 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_30.jpg';
import SummerHouse204WildDunes_31 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_31.jpg';
import SummerHouse204WildDunes_32 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_32.jpg';
import SummerHouse204WildDunes_33 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_33.jpg';
import SummerHouse204WildDunes_34 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_34.jpg';
import SummerHouse204WildDunes_35 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_35.jpg';
import SummerHouse204WildDunes_36 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_36.jpg';
import SummerHouse204WildDunes_37 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_37.jpg';
import SummerHouse204WildDunes_38 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_38.jpg';
import SummerHouse204WildDunes_39 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_39.jpg';
import SummerHouse204WildDunes_40 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_40.jpg';
import SummerHouse204WildDunes_41 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_41.jpg';
import SummerHouse204WildDunes_42 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_42.jpg';
import SummerHouse204WildDunes_43 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_43.jpg';
import SummerHouse204WildDunes_44 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_44.jpg';
import SummerHouse204WildDunes_45 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_45.jpg';
import SummerHouse204WildDunes_46 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_46.jpg';
import SummerHouse204WildDunes_47 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_47.jpg';
import SummerHouse204WildDunes_48 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_48.jpg';
import SummerHouse204WildDunes_49 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_49.jpg';
import SummerHouse204WildDunes_50 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_50.jpg';
import SummerHouse204WildDunes_51 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_51.jpg';
import SummerHouse204WildDunes_52 from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_52.jpg';

function SummerHouse204WildDunes() {


    var settings = {
        dots: true,
        //infinite: false,
        arrows: false,
        infinite: true,
        //speed: 5000,
        autoplaySpeed: 5000,
        //autoplay: true,
        autoplay: false,
        adaptiveHeight: false,
        slidesToShow: 1,
        pauseOnFocus: true,
        cssEase: 'ease',
        fade: true,
        pauseOnHover: true,
        swipeToSlide: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="main_div">
            <Header />
            <div className="page-header-with-back-image-title-subtitle" style={{ backgroundImage: `url("${SummerHouse204WildDunes_1}")` }} >
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <div className="page-header-with-back-image-title-subtitle-inner">
                                <h1>204 Summer House – Wild Dunes</h1>
                                <h3>START FROM <span>$110</span> <sub>/ NIGHT</sub></h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-properties-gallery-outer">
                <Container>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_1} image={SummerHouse204WildDunes_1} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_2} image={SummerHouse204WildDunes_2} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_3} image={SummerHouse204WildDunes_3} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_4} image={SummerHouse204WildDunes_4} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_5} image={SummerHouse204WildDunes_5} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_6} image={SummerHouse204WildDunes_6} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_7} image={SummerHouse204WildDunes_7} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_8} image={SummerHouse204WildDunes_8} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_9} image={SummerHouse204WildDunes_9} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_10} image={SummerHouse204WildDunes_10} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_11} image={SummerHouse204WildDunes_11} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_12} image={SummerHouse204WildDunes_12} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_13} image={SummerHouse204WildDunes_13} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_14} image={SummerHouse204WildDunes_14} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_15} image={SummerHouse204WildDunes_15} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_16} image={SummerHouse204WildDunes_16} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_17} image={SummerHouse204WildDunes_17} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_18} image={SummerHouse204WildDunes_18} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_19} image={SummerHouse204WildDunes_19} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_20} image={SummerHouse204WildDunes_20} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_21} image={SummerHouse204WildDunes_21} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_22} image={SummerHouse204WildDunes_22} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_23} image={SummerHouse204WildDunes_23} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_24} image={SummerHouse204WildDunes_24} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_25} image={SummerHouse204WildDunes_25} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_26} image={SummerHouse204WildDunes_26} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_27} image={SummerHouse204WildDunes_27} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_28} image={SummerHouse204WildDunes_28} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_29} image={SummerHouse204WildDunes_29} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_30} image={SummerHouse204WildDunes_30} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_31} image={SummerHouse204WildDunes_31} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_32} image={SummerHouse204WildDunes_32} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_33} image={SummerHouse204WildDunes_33} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_34} image={SummerHouse204WildDunes_34} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_35} image={SummerHouse204WildDunes_35} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_36} image={SummerHouse204WildDunes_36} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_37} image={SummerHouse204WildDunes_37} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_38} image={SummerHouse204WildDunes_38} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_39} image={SummerHouse204WildDunes_39} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_40} image={SummerHouse204WildDunes_40} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_41} image={SummerHouse204WildDunes_41} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_42} image={SummerHouse204WildDunes_42} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_43} image={SummerHouse204WildDunes_43} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_44} image={SummerHouse204WildDunes_44} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_45} image={SummerHouse204WildDunes_45} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_46} image={SummerHouse204WildDunes_46} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_47} image={SummerHouse204WildDunes_47} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_48} image={SummerHouse204WildDunes_48} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_49} image={SummerHouse204WildDunes_49} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_50} image={SummerHouse204WildDunes_50} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_51} image={SummerHouse204WildDunes_51} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={SummerHouse204WildDunes_52} image={SummerHouse204WildDunes_52} /></div>
                </Container>
            </div>
            <div className="single-property-description">
                <div className="single-property-description-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-title-inner"><h4>Description Of Properties</h4></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-description-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-content-inner">
                                    <p>OCEANFRONT. This is why you come to the beach. We want to give you that from the balcony, the living room, the dining room, the kitchen and the master bedroom. Take a good look at our pictures and video. You know what they say……’a picture is worth a thousand words’.</p>
                                    <p>RATES. Our rates include everything but housekeeping. Nothing is going to be added on to them. Even more important…..at the risk of sounding like a used car salesman…..we want your business. So please send us the VRBO/HomeAway listing number of someone who might have a lower price. I’ll try to beat that price by at least fifty dollars.</p>
                                    <p>RENOVATIONS. This is what we have just recently done: all new porch furniture (4 chairs and glass top table, painted entire apartment, new wall-to-wall carpet in 2nd bedroom, all other carpets cleaned, master bath gutted and replaced with shower (non-slip pebbles) plus non-slip slate floor, 2 safety grab bars, sliding glass doors, “high boy” toilet, the 2nd bath redo, new hot water heater, new HVAC system, deep cleaned on a regular basis, new pots/pans, more glasses/plates and last but not least new screen doors for the balcony Hope we haven’t made it so nice that you won’t want to go out and walk on the beach!</p>
                                    <p>In addition to photos I will be email you some video links. You know what they say about ‘assume’, and I am old enough to have learned my lesson many times over. But here I go again. I assume you know something about the Wild Dunes Resort, but in case you don’t please scroll down to ‘Links to more information:’ and click on ‘Wild Dunes Resort’. Information about ‘Charleston’ is included with a second click below. Please utilize it to broaden your knowledge of the area and all of it’s offerings.</p>
                                    <p>Please be careful not to walk on the floors with golf spikes.</p>
                                    <p>The calendar is not always up to date. So please check with me for availability. The rental rate is not subject to change unless in your research you find a better price. I, of-course, will appreciate hearing about whether you are a member of AARP, AAA, MADD etc, but it won’t affect your rental rate.</p>
                                    <p>NOTEWORTHY – Non-smoking. The villa is larger than most two bedrooms at 1500 square feet. Bed arrangement includes a king bed in the master suite, two twin beds in the second bedroom, and a sleeper sofa in the living room…..accommodating in all six guests. The granite counters, hard wood and tile floors add a touch of elegance. You will love the massive flat screen TV. Linens and towels are provided, washer and dryer, air conditioning, starter supply of paper products and detergents, fully equipped kitchen with dinnerware, flatware, pots and pans, plus local telephone service. Wild Dunes’ amenities include golf, dining, and bike trails. The rental includes: free parking for three cars, elevator access, free access to community swimming pool, and 24-hour security in a gated community.</p>
                                    {/* <p>FREE WIFI</p>
                                    <p>Year Round. We only rent by the week. Our rates (TAX INCLUDED) are: Peak Summer week: $2695, First 2 weeks of June: $2095, Last 2 weeks of August: $1795, May week: $1795, April week: $1695, March week: $1495, September week: $1495, October week: $1295, Weeks November thru February $995. 3 months January thru March $5995. Holiday Surcharge 10% ( Thanksgiving, Christmas,r New Years, Easter, Memorial Day, July 4th anf Labor Day)</p>
                                    <p>HIGHLIGHTS IN REVIEW: 1)If you are requesting a lower price than our posted rate, then we need the web link to the lower priced one you are considering. 2) Check calendar for availability before submitting an inquiry. 3) Review the 50 pictures and video. 4) Read comments by past guests. 5) Thank you so much for considering our place.</p>
                                    <p>I usually respond to an inquiry within 24 hours of receipt.</p>
                                    <p>ONCE YOU SUBMIT AN INQUIRY, YOUR FIRST COMMUNICATION WILL COME FROM VRBO/HOMEAWAY. VERY IMPORTANT TO READ IT CAREFULLY ESPECIALLY IN REGARD TO SECURITY.</p> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="single-property-video">
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <iframe title="204 Summerhouse" width="100%" height="686" src="https://www.youtube.com/embed/ytQwRo6uLB8?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-property-amenities">
                <div className="single-property-amenities-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-amenities-title-inner mb-4"><h4>Highlights</h4></div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <div className="single-property-amenities-content-inner-content">
                            <Row>
                                <Col lg={4} md={4} sm={12}><ul><li>Property Type: Condo</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Area: 1,500 Square Feet</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-in: 4:00 PM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-out: 11:00 AM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Maximum Number Of Guests: 6</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Minimum Age Of Primary Renter: 25</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Children Welcome</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Parties/Events NOT ALLOWED</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Pets Not Allowed</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Non Smoking Only</li></ul></Col>
                                <Col lg={8} md={8} sm={12}><ul><li>CANCELLATION – Full Refund Less $75 If We Can Re-Rent Your Booking Period</li></ul></Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={12} sm={12}><ul><li>Location Type: Beachfront, Resort, Oceanfront</li></ul></Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="single-property-amenities-content">
                    <Container>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities Unit And Ocean Club</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>2&nbsp; Bedrooms, Sleeps 6</li>
                                            <li>2 Bathrooms</li>
                                            <li>1 King Sized Bed, 2 Twin Or Single Beds</li>
                                            <li>Sofa Bed&nbsp;</li>
                                            <li>Satellite, Cable TV, DVD Player, Wireless Internet</li>
                                            <li>Balcony Overlooking Pool With Views Of Atlantic</li>
                                            <li>Heating and AC </li>
                                            <li>Hair Dryer, Laundry Soap, Paper Towels, Basic Soaps, Toilet Paper </li>
                                            <li>Washer and Dryer, Ironing Board </li>
                                            <li>Ceiling Fans</li>
                                            <li>Oven, Stove, Dishwasher, Refrigerator, Microwave </li>
                                            <li>Blender, Coffee Maker </li>
                                            <li>Dishes And Cooking Utensils </li>
                                            <li>Spacious Dining Room</li>
                                            <li>Towels And Linens Provided</li>
                                            <li>Beach Chairs</li>
                                            <li>Elevator And Private Parking</li>
                                            <li>Grill</li>
                                            <li>Outdoor Pool At Summer Place</li>
                                            <li>Walkway To The Beach</li>
                                            <li>Low Allergen Environment</li>
                                            <li>Long Term Renters Welcome</li>
                                            <li>Wheelchair Accesible</li>
                                            <li>Suitable For Elderly Or Infirm</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities Wild Dunes</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>2 – 18 Hole Championship Golf Courses</li>
                                            <li>Miles of Bike/Running Paths</li>
                                            <li>Shopping And Restaurants In Community</li>
                                            <li>Gated And Private Access </li>
                                            <li>Basketball Court and Playgrounds </li>
                                            <li>Marina, Trailer Parking, Boat Launch</li>
                                        </ul>
                                    </div>
                                    <div className="single-property-amenities-content-inner-title"><h6>Things To Do Charleston</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>Many Amazing Shops And Restaurants</li>
                                            <li>Historical Carriage Tours</li>
                                            <li>Wine Tours</li>
                                            <li>Scenic Drives And Wildlife Viewing</li>
                                            <li>Horseback Riding</li>
                                            <li>Parasailing</li>
                                            <li>Deep Sea Fishing</li>
                                            <li>Speed Boat Adventures And Ghost Tours</li>
                                            <li>High Ropes Course Adventure</li>
                                            <li>Shem Creek Bar And Restaurant Tour</li>
                                            <li>Gibbes Museum Of Art</li>
                                            <li>Haunted Jail Tour</li>
                                            <li>Fort Sumter Tours</li>
                                            <li>South Carolina Aquarium</li>
                                            <li>Patriots Point Naval And Maritime Museum</li>
                                            <li>Mount Pleasant and Holly Beach Piers</li>
                                            <li>Whirlin’ Waters Adventure Waterpark</li>
                                            <li>Isle Of Palms County Park </li>
                                            <li>Splash Island Waterpark</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
            <div className="single-property-testimonials">
                <div className="single-property-testimonials-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-title-inner">
                                    <h4>Testimonials</h4>
                                    <p>What People Say About Us</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-testimonials-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-content-inner">
                                    <div className="single-property-testimonials-slider">
                                        <Slider className="single-property-testimonials-slider" {...settings} >
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>We have stayed at this condo twice now and will do so again, it is easy to do beach activities, and walk throughout the resort before scooting over to downtown Charleston for the evening.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Christina</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>We had a great 10 day stay on IOP! When they say right on the beach they mean right on the beach. Sitting on the deck with coffee or an adult beverage was wonderful. The unit was well laid out, furniture was comfy including the beds and kitchen was well equipped. Plenty of towels and nice convenience of the in unit laundry. We were able to cook a few meals and found everything we needed in terms of pots, utensils, etc. We’ve already booked our visit next November!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Karen</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Great location with walking distance to the links course, Huey’s diner and of course, the ocean in the back yard! Would definitely recommend!!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Mike</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-price-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className="mb-3">Rates</h3>
                                <p className="left">PLEASE NOTE: There is a $156 housekeeping fee. There is 10% Holiday Surcharge (New Years, Easter, Memorial Day, July 4th, Labor Day, Thanksgiving and Christmas). Summer rents by the week only.  Three night minimum September thru May.</p>
                                <p>IN THE SUMMER, THESE ARE THE WEEKLY RATES</p>
                                <p>First two weeks in June Base Cost $2,095.00  Cleaning: $156  TOTAL:  $2,245.00</p>
                                <p>June, July, August Base Cost $2,695.00 Cleaning: $156  TOTAL:  $2,845.00</p>
                                <p>Last two weeks in August Base Cost $1,795.00  Cleaning: $156  TOTAL:  $1,945.00</p>
                                <p>September     Base Cost: $1,495*</p>
                                <p>October     Base Cost: $1,295*</p>
                                <p>November thru February     Base Cost: $995*</p>
                                <p>* Cleaning Fee $156</p>
                                <p>Holiday Surcharge (Thanksgiving, Christmas, New Years) $200</p>
                                <p>Three month rental $5,995</p>
                                <h3 className="mt-5">Making a Reservation</h3>
                                <h6 className="mt-2">It is imperative that the information we give you is YOUR full rental cost and availability is tailored to YOUR particular wishes and up to date with our present schedule of bookings.</h6>
                                <h6 className="mt-2">Email me at: <a href="mailto:coleresortrentals@gmail.com">coleresortrentals@gmail.com</a>, writing down the rental location you want to go to and the dates you would like. We will be back in touch ASAP.</h6>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SummerHouse204WildDunes;