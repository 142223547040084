import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import logo from '../assets/image/logo-white.png';

export default function Header() {
    return (
        <div>
            <header>
                <Container>
                    <Row className="align-items-center">
                        <Col lg={3} xs={6}>
                            <div className="site-logo"><a className="logo-link" href="/"><img src={logo} alt="SC Beach Vacations" width="160" height="60" /></a></div>
                        </Col>
                        <Col lg={9} xs={6}>
                            <div className="site-main-menu">
                                <div className="fusion-mobile-menu-icons"><a href="javascript:void(0);" className="fusion-mobile-menu-icons-bars"><FontAwesomeIcon icon={faBars} /></a></div>
                                <ul id="menu-menu" className="site-main-menu-ul clearfix display-desktop">
                                    <li className="menu-item menu-item-1"><a href="/"><span className="menu-text">Home</span></a></li>
                                    <li className="menu-item menu-item-2"><a href="javascript:void(0);"><span className="menu-text">Properties</span></a><span className="fusion-caret"><FontAwesomeIcon icon={faChevronDown} /></span>
                                        <ul className="sub-menu">
                                            <li className="sub-menu-item sub-menu-item-1"><a href="javascript:void(0);"><span className="menu-text">Charleston Properties</span></a><span className="fusion-caret"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                <ul className="sub-sub-menu">
                                                     <li className="sub-sub-menu-item sub-sub-menu-item-1"><a href="/1504-ocean-club-wild-dunes"><span className="menu-text">1504 Ocean Club – Wild Dunes</span></a></li>
                                                     {/* <li className="sub-sub-menu-item sub-sub-menu-item-1"><a href="/1104-ocean-club-wild-dunes"><span className="menu-text">1104 Ocean Club – Wild Dunes</span></a></li> */}
                                                     {/* <li className="sub-sub-menu-item sub-sub-menu-item-1"><a href="/1b-seagrove-wild-dunes"><span className="menu-text">1B Seagrove – Wild Dunes</span></a></li> */}
                                                     {/* <<li className="sub-sub-menu-item sub-sub-menu-item-1"><a href="/204-summer-house-wild-dunes"><span className="menu-text">204 Summer House – Wild Dunes</span></a></li>*/}
                                                     {/*<li className="sub-sub-menu-item sub-sub-menu-item-1"><a href="/a-301-port-o-call-wild-dunes"><span className="menu-text">A-301 Port O’ Call – Wild Dunes</span></a></li> */}
                                                </ul>
                                            </li>
                                            <li className="sub-menu-item sub-menu-item-2"><a href="javascript:void(0);"><span className="menu-text">North Myrtle Beach Properties</span></a><span className="fusion-caret"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                <ul className="sub-sub-menu">
                                                    <li className="sub-sub-menu-item sub-sub-menu-item-1"><a href="/1705-the-ashworth-oceanfront"><span className="menu-text">1705 The Ashworth – Oceanfront</span></a></li>
                                                    {/*<li className="sub-sub-menu-item sub-sub-menu-item-2"><a href="/403-wellington-oceanfront"><span className="menu-text">403 Wellington – Oceanfront</span></a></li> */}
                                                </ul>
                                            </li>
                                            <li className="sub-menu-item sub-menu-item-3"><a href="javascript:void(0);"><span className="menu-text">Other Properties</span></a><span className="fusion-caret"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                <ul className="sub-sub-menu">
                                                    {/* <li className="sub-sub-menu-item sub-sub-menu-item-1"><a href="/lake-lanier-western-style-log-home"><span className="menu-text">Lake Lanier Western Style Log Home</span></a></li> */}
                                                    <li className="sub-sub-menu-item sub-sub-menu-item-2"><a href="/contemporary-home-on-beautiful-lake-hartwell"><span className="menu-text">Contemporary Home on Beautiful Lake Hartwell</span></a></li>
                                                    {/* <li className="sub-sub-menu-item sub-sub-menu-item-3"><a href="/rustic-log-cabin-in-the-woods-of-north-georgia"><span className="menu-text">Rustic Log Cabin in the Woods of North Georgia</span></a></li> */}
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item menu-item-3"><a href="/wild-dunes-community"><span className="menu-text">Wild Dunes Community</span></a></li>
                                    <li className="menu-item menu-item-4"><a href="/north-myrtle-area"><span className="menu-text">North Myrtle Area</span></a></li>
                                    <li className="menu-item menu-item-5"><a href="/about"><span className="menu-text">About Us</span></a></li>
                                    <li className="menu-item menu-item-6"><a href="/contact"><span className="menu-text">Contact</span></a></li>
                                </ul>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </header>
        </div>
    )
}