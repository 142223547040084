import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../common/footer";
import Header from "../common/header";

import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ContemporaryHomeonBeautifulLakeHartwell_1 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_1.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_2 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_2.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_3 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_3.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_4 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_4.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_5 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_5.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_6 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_6.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_7 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_7.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_8 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_8.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_9 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_9.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_10 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_10.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_11 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_11.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_12 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_12.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_13 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_13.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_14 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_14.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_15 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_15.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_16 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_16.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_17 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_17.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_18 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_18.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_19 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_19.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_20 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_20.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_21 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_21.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_22 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_22.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_23 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_23.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_24 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_24.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_25 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_25.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_26 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_26.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_27 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_27.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_28 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_28.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_29 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_29.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_30 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_30.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_31 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_31.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_32 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_32.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_33 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_33.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_34 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_34.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_35 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_35.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_36 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_36.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_37 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_37.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_38 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_38.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_39 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_39.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_40 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_40.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_41 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_41.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_42 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_42.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_43 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_43.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_44 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_44.jpg';
import ContemporaryHomeonBeautifulLakeHartwell_45 from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_45.jpg';

function ContemporaryHomeonBeautifulLakeHartwell() {


    var settings = {
        dots: true,
        //infinite: false,
        arrows: false,
        infinite: true,
        //speed: 5000,
        autoplaySpeed: 5000,
        //autoplay: true,
        autoplay: false,
        adaptiveHeight: false,
        slidesToShow: 1,
        pauseOnFocus: true,
        cssEase: 'ease',
        fade: true,
        pauseOnHover: true,
        swipeToSlide: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="main_div">
            <Header />
            <div className="page-header-with-back-image-title-subtitle" style={{ backgroundImage: `url("${ContemporaryHomeonBeautifulLakeHartwell_36}")` }} >
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <div className="page-header-with-back-image-title-subtitle-inner">
                                <h1>Contemporary Home on Beautiful Lake Hartwell</h1>
                                <h3>START FROM <span>$325</span> <sub>/ NIGHT</sub></h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-properties-gallery-outer">
                <Container>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_1} image={ContemporaryHomeonBeautifulLakeHartwell_1} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_2} image={ContemporaryHomeonBeautifulLakeHartwell_2} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_3} image={ContemporaryHomeonBeautifulLakeHartwell_3} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_4} image={ContemporaryHomeonBeautifulLakeHartwell_4} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_5} image={ContemporaryHomeonBeautifulLakeHartwell_5} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_6} image={ContemporaryHomeonBeautifulLakeHartwell_6} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_7} image={ContemporaryHomeonBeautifulLakeHartwell_7} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_8} image={ContemporaryHomeonBeautifulLakeHartwell_8} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_9} image={ContemporaryHomeonBeautifulLakeHartwell_9} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_10} image={ContemporaryHomeonBeautifulLakeHartwell_10} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_11} image={ContemporaryHomeonBeautifulLakeHartwell_11} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_12} image={ContemporaryHomeonBeautifulLakeHartwell_12} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_13} image={ContemporaryHomeonBeautifulLakeHartwell_13} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_14} image={ContemporaryHomeonBeautifulLakeHartwell_14} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_15} image={ContemporaryHomeonBeautifulLakeHartwell_15} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_16} image={ContemporaryHomeonBeautifulLakeHartwell_16} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_17} image={ContemporaryHomeonBeautifulLakeHartwell_17} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_18} image={ContemporaryHomeonBeautifulLakeHartwell_18} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_19} image={ContemporaryHomeonBeautifulLakeHartwell_19} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_20} image={ContemporaryHomeonBeautifulLakeHartwell_20} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_21} image={ContemporaryHomeonBeautifulLakeHartwell_21} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_22} image={ContemporaryHomeonBeautifulLakeHartwell_22} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_23} image={ContemporaryHomeonBeautifulLakeHartwell_23} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_24} image={ContemporaryHomeonBeautifulLakeHartwell_24} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_25} image={ContemporaryHomeonBeautifulLakeHartwell_25} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_26} image={ContemporaryHomeonBeautifulLakeHartwell_26} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_27} image={ContemporaryHomeonBeautifulLakeHartwell_27} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_28} image={ContemporaryHomeonBeautifulLakeHartwell_28} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_29} image={ContemporaryHomeonBeautifulLakeHartwell_29} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_30} image={ContemporaryHomeonBeautifulLakeHartwell_30} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_31} image={ContemporaryHomeonBeautifulLakeHartwell_31} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_32} image={ContemporaryHomeonBeautifulLakeHartwell_32} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_33} image={ContemporaryHomeonBeautifulLakeHartwell_33} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_34} image={ContemporaryHomeonBeautifulLakeHartwell_34} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_35} image={ContemporaryHomeonBeautifulLakeHartwell_35} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_36} image={ContemporaryHomeonBeautifulLakeHartwell_36} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_37} image={ContemporaryHomeonBeautifulLakeHartwell_37} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_38} image={ContemporaryHomeonBeautifulLakeHartwell_38} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_39} image={ContemporaryHomeonBeautifulLakeHartwell_39} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_40} image={ContemporaryHomeonBeautifulLakeHartwell_40} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_41} image={ContemporaryHomeonBeautifulLakeHartwell_41} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_42} image={ContemporaryHomeonBeautifulLakeHartwell_42} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_43} image={ContemporaryHomeonBeautifulLakeHartwell_43} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_44} image={ContemporaryHomeonBeautifulLakeHartwell_44} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={ContemporaryHomeonBeautifulLakeHartwell_45} image={ContemporaryHomeonBeautifulLakeHartwell_45} /></div>
                </Container>
            </div>
            <div className="single-property-description">
                <div className="single-property-description-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-title-inner"><h4>Description Of Properties</h4></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-description-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-content-inner">
                                    <p>Clean Contemporary Home on a wooded lot on beautiful Lake Hartwell</p>
                                    <p>Ready for a romantic getaway or a trip to the lake with family and friends, this is the home for you. Besides the boating, fishing, swimming, and just lounging by the lake this home offers beautiful artwork, a game room with pool table and foosball, and wonderful restaurants and experiences close by. It’s on a heavily wooded lot so don’t expect grand vistas from the house but if you want vistas head for the dock or a boat.</p>
                                    <p>The space<br />
                                        Entertainment systems are smart TV’s so you will need to use Roku, Apple TV, or your own Netflix account. The kitchen is small so if you are planning a feast please have one or two designated cooks. The hot tub is off limits and not part of the rental.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="single-property-amenities">
                <div className="single-property-amenities-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-amenities-title-inner mb-4"><h4>Highlights</h4></div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <div className="single-property-amenities-content-inner-content">
                            <Row>
                                <Col lg={4} md={4} sm={12}><ul><li>Property Type: Entire Home</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-in: 4:00 PM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-out: 1:00 AM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Maximum Number Of Guests: 8</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Minimum Age Of Primary Renter: 25</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Pets Not Allowed</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Parties/Events NOT ALLOWED</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Non Smoking Only</li></ul></Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="single-property-amenities-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities Unit 403</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <Row>
                                            <Col lg={4} md={4} sm={12}><ul><li>4 Bedrooms, Sleeps 8</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>2.5 Bathrooms</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>3 Queen Size Beds, 2 Single Beds</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>TV</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Washer</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Dryer</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Smoke alarm</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Wifi</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Carbon monoxide alarm</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Air conditioning</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>First aid kit</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Fire extinguisher</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Kitchen</li></ul></Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
            <div className="single-property-testimonials">
                <div className="single-property-testimonials-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-title-inner">
                                    <h4>Testimonials</h4>
                                    <p>What People Say About Us</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-testimonials-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-content-inner">
                                    <div className="single-property-testimonials-slider">
                                        <Slider className="single-property-testimonials-slider" {...settings} >
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>The location on the lake is great, love the covered boat dock!! (Also, doesn’t hurt having a boat launch only a few minutes away!)</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Heather</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>This contemporary home has many windows and porches for relaxing with a view of a bit of the lake. The beds were comfortable and the bathrooms were very clean. It definitely met our needs and would be a great place to stay during the summer.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Lenora</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Perfect location, the lake was amazing if you don’t have a boat to bring I recommend renting one as it was a perfect place to view the sites. There are even little islands you can beach at. The host provided us with many things to do, eat, and places to visit</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Paige</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>A beautiful and spacious home on a gorgeous cove. Fantastic getaway experience, would highly recommend. Excellent host communication and directions.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Jordan</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Great lake location. The cove the house sits in is beautiful and peaceful. Great place to relax spend time with the family.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>David</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Fun house on a private cove! Such a perfect getaway for my family of four. The path down to lake is no joke-can’t imagine doing it with a young child or elderly family member. Thanks for letting us enjoy the space!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Laura</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-price-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                {/* <h3 className="mb-3">Making a Reservation</h3>
                                <p>It is imperative that the information we give you is YOUR full rental cost and availability is tailored to YOUR particular wishes and up to date with our present schedule of bookings.</p>
                                <p>Email me at: <a href="mailto:david@redground.land">david@redground.land</a>, writing down the rental location you want to go to and the dates you would like. We will be back in touch ASAP.</p> */}<h3 className="mt-5">Making a Reservation</h3>
                                <h6 className="mt-2">It is imperative that the information we give you is YOUR full rental cost and availability is tailored to YOUR particular wishes and up to date with our present schedule of bookings.</h6>
                                <h6 className="mt-2">Email me at: <a href="mailto:coleresortrentals@gmail.com">coleresortrentals@gmail.com</a>, writing down the rental location you want to go to and the dates you would like. We will be back in touch ASAP.</h6>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ContemporaryHomeonBeautifulLakeHartwell;