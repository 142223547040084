import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import logo from '../assets/image/logo-white.png';
import instagram from '../assets/image/instagram.png';
import twitter from '../assets/image/twitter.png';
import facebook from '../assets/image/facebook.png';
import youtube from '../assets/image/youtube.png';

export default function Footer() {
    return (
        <div>
            <footer>
                <div className="footer-widget-container">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={4} md={6} className="column_1 footer-widget-column">
                                <div className="footer-widget-wrapper footer-widget-logo">
                                    <div className="footer-widget-wrapper-inner">
                                        <div className="footer-widget-content">
                                            <div className="footer-widget-logo-inner"><a href="/"><img src={logo} alt="Logo"/></a></div></div>
                                    </div>
                                </div>
                                <div className="footer-widget-wrapper footer-widget-text">
                                    <div className="footer-widget-wrapper-inner">
                                        <div className="footer-widget-content">
                                            <div className="footer-widget-text-inner"><p>From Atlanta in 2017, David started a property management company and in late 2018 David and Peter merged to form a business partnership called Red Ground,LLC.</p></div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col lg={4} md={6} className="column_2 footer-widget-column">
                                <div className="footer-widget-wrapper footer-widget-text footer-widget-text-one">
                                    <div className="footer-widget-wrapper-inner">
                                        <div className="footer-widget-title"><h5>Peter Cole</h5></div>
                                        <div className="footer-widget-content">
                                            <ul>
                                                <li className="address">411 O’Henry Avenue Davidson, NC 28036</li>
                                                <li className="email"><a href="mailto:coleresortrentals@gmail.com">coleresortrentals@gmail.com</a></li>
                                                <li className="tel"><a href="tel:+1(704)975-4744">+1 (704) 975-4744</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Col> */}
                            <Col lg={4} md={6} className="offset-md-4 column_3 footer-widget-column">
                                <div className="footer-widget-wrapper footer-widget-text footer-widget-text-one">
                                    <div className="footer-widget-wrapper-inner">
                                        <div className="footer-widget-title"><h5>David Bell</h5></div>
                                        <div className="footer-widget-content">
                                            <ul>
                                                <li className="address">3870 Peachtree Industrial Blvd., Ste. 340-285 Duluth, GA 30097</li>
                                                <li className="email"><a href="mailto:David@RedGround.Land">David@RedGround.Land</a></li>
                                                <li className="tel"><a href="tel:+1(678)480-9975">+1 (678) 480-9975</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="footer-copyright-container">
                    <Container>
                        <Row className="align-items-center">
                            <Col md={8}>
                                <div className="footer-copyright"><p>2023 Red Ground LLC. All Rights Reserved. Design &amp; Developed  <a href="https://cipherbrains.com" target="_blank">CipherBrains Technologies</a></p></div>
                            </Col>
                            <Col md={4}>
                                <div className="footer-social">
                                    <ul>
                                        <li><a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram"/></a></li>
                                        <li><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter"/></a></li>
                                        <li><a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook"/></a></li>
                                        <li><a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer"><img src={youtube} alt="youtube"/></a></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </div>
    )
}