import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../common/footer";
import Header from "../common/header";

import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'

import RusticLogCabinintheWoodsofNorthGeorgia_1 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_1.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_2 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_2.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_3 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_3.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_4 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_4.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_5 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_5.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_6 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_6.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_7 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_7.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_8 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_8.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_9 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_9.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_10 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_10.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_11 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_11.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_12 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_12.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_13 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_13.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_14 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_14.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_15 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_15.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_16 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_16.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_17 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_17.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_18 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_18.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_19 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_19.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_20 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_20.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_21 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_21.jpg';
import RusticLogCabinintheWoodsofNorthGeorgia_22 from '../assets/image/RusticLogCabinintheWoodsofNorthGeorgia/Rustic_Log_Cabin_in_the_Woods_of_North_Georgia_22.jpg';

function RusticLogCabinintheWoodsofNorthGeorgia() {

    return (
        <div className="main_div">
            <Header />
            <div className="page-header-with-back-image-title-subtitle" style={{ backgroundImage: `url("${RusticLogCabinintheWoodsofNorthGeorgia_3}")` }} >
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <div className="page-header-with-back-image-title-subtitle-inner">
                                <h1>Rustic Log Cabin in the Woods of North Georgia</h1>
                                {/* <h3>START FROM <span>$325</span> <sub>/ NIGHT</sub></h3> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-properties-gallery-outer">
                <Container>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_1} image={RusticLogCabinintheWoodsofNorthGeorgia_1} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_2} image={RusticLogCabinintheWoodsofNorthGeorgia_2} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_3} image={RusticLogCabinintheWoodsofNorthGeorgia_3} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_4} image={RusticLogCabinintheWoodsofNorthGeorgia_4} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_5} image={RusticLogCabinintheWoodsofNorthGeorgia_5} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_6} image={RusticLogCabinintheWoodsofNorthGeorgia_6} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_7} image={RusticLogCabinintheWoodsofNorthGeorgia_7} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_8} image={RusticLogCabinintheWoodsofNorthGeorgia_8} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_9} image={RusticLogCabinintheWoodsofNorthGeorgia_9} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_10} image={RusticLogCabinintheWoodsofNorthGeorgia_10} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_11} image={RusticLogCabinintheWoodsofNorthGeorgia_11} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_12} image={RusticLogCabinintheWoodsofNorthGeorgia_12} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_13} image={RusticLogCabinintheWoodsofNorthGeorgia_13} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_14} image={RusticLogCabinintheWoodsofNorthGeorgia_14} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_15} image={RusticLogCabinintheWoodsofNorthGeorgia_15} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_16} image={RusticLogCabinintheWoodsofNorthGeorgia_16} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_17} image={RusticLogCabinintheWoodsofNorthGeorgia_17} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_18} image={RusticLogCabinintheWoodsofNorthGeorgia_18} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_19} image={RusticLogCabinintheWoodsofNorthGeorgia_19} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_20} image={RusticLogCabinintheWoodsofNorthGeorgia_20} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_21} image={RusticLogCabinintheWoodsofNorthGeorgia_21} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={RusticLogCabinintheWoodsofNorthGeorgia_22} image={RusticLogCabinintheWoodsofNorthGeorgia_22} /></div>
                </Container>
            </div>
            <div className="single-property-description">
                <div className="single-property-description-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-title-inner"><h4>Description Of Properties</h4></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-description-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-content-inner">
                                    <p>This log cabin in the woods is the perfect place to get off the grid for awhile and enjoy peace and solitude. It is a one room cabin with a sleeping loft, kitchenette and a composting toilet. The shower is in a separate small building close by and the water is set at a constant 110 degrees. The stairway to the loft raises above head level during the day to give you more room to move about.</p>
                                    <p>The space<br />
                                        The cabin is set in the woods by a small spring fed stream. There is an outdoor wood stove, grill and firepit and plenty of firewood. There is electricity and running water but questionable wifi and spotty cell phone service. Perfect for someone who needs an excuse not to text or respond to email, who is trying to get off the grid for a while and relax. This space gives you time to breath and read a good book.</p>
                                    <p>Guest access<br />
                                        You may access the cabin and the surrounding area, there is a path through the woods that you may want to check out. The firepit is down the road closer to the owner’s house but you are welcome to use that space. There is also a wood stove and grill near the front of the cabin.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="single-property-amenities">
                <div className="single-property-amenities-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-amenities-title-inner mb-4"><h4>Highlights</h4></div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <div className="single-property-amenities-content-inner-content">
                            <Row>
                                <Col lg={4} md={4} sm={12}><ul><li>Property Type: Entire Home</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-in: 3:00 PM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-out: 10:00 AM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Maximum Number Of Guests: 2</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Not suitable for children and infants</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Pets Not Allowed</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Parties/Events NOT ALLOWED</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Non Smoking Only</li></ul></Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="single-property-amenities-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities Unit 403</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <Row>
                                            <Col lg={4} md={4} sm={12}><ul><li>1 Bedroom, Sleeps 2</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Half Bath</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>1 Bed</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Smoke alarm</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Carbon monoxide alarm</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Air conditioning</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>First aid kit</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Fire extinguisher</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Kitchen</li></ul></Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-price-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className="mb-3">Making a Reservation</h3>
                                <p>It is imperative that the information we give you is YOUR full rental cost and availability is tailored to YOUR particular wishes and up to date with our present schedule of bookings.</p>
                                <p>Email me at: <a href="mailto:david@redground.land">david@redground.land</a>, writing down the rental location you want to go to and the dates you would like. We will be back in touch ASAP.</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
            <Footer />
        </div>
    );
}

export default RusticLogCabinintheWoodsofNorthGeorgia;