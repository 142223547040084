import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../common/footer";
import Header from "../common/header";

import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Seagrove1BWildDunes_1 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_1.jpg';
import Seagrove1BWildDunes_2 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_2.jpg';
import Seagrove1BWildDunes_3 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_3.jpg';
import Seagrove1BWildDunes_4 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_4.jpg';
import Seagrove1BWildDunes_5 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_5.jpg';
import Seagrove1BWildDunes_6 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_6.jpg';
import Seagrove1BWildDunes_7 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_7.jpg';
import Seagrove1BWildDunes_8 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_8.jpg';
import Seagrove1BWildDunes_9 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_9.jpg';
import Seagrove1BWildDunes_10 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_10.jpg';
import Seagrove1BWildDunes_11 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_11.jpg';
import Seagrove1BWildDunes_12 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_12.jpg';
import Seagrove1BWildDunes_13 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_13.jpg';
import Seagrove1BWildDunes_14 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_14.jpg';
import Seagrove1BWildDunes_15 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_15.jpg';
import Seagrove1BWildDunes_16 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_16.jpg';
import Seagrove1BWildDunes_17 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_17.jpg';
import Seagrove1BWildDunes_18 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_18.jpg';
import Seagrove1BWildDunes_19 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_19.jpg';
import Seagrove1BWildDunes_20 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_20.jpg';
import Seagrove1BWildDunes_21 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_21.jpg';
import Seagrove1BWildDunes_22 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_22.jpg';
import Seagrove1BWildDunes_23 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_23.jpg';
import Seagrove1BWildDunes_24 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_24.jpg';
import Seagrove1BWildDunes_25 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_25.jpg';
import Seagrove1BWildDunes_26 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_26.jpg';
import Seagrove1BWildDunes_27 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_27.jpg';
import Seagrove1BWildDunes_28 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_28.jpg';
import Seagrove1BWildDunes_29 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_29.jpg';
import Seagrove1BWildDunes_30 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_30.jpg';
import Seagrove1BWildDunes_31 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_31.jpg';
import Seagrove1BWildDunes_32 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_32.jpg';
import Seagrove1BWildDunes_33 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_33.jpg';
import Seagrove1BWildDunes_34 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_34.jpg';
import Seagrove1BWildDunes_35 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_35.jpg';
import Seagrove1BWildDunes_36 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_36.jpg';
import Seagrove1BWildDunes_37 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_37.jpg';
import Seagrove1BWildDunes_38 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_38.jpg';
import Seagrove1BWildDunes_39 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_39.jpg';
import Seagrove1BWildDunes_40 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_40.jpg';
import Seagrove1BWildDunes_41 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_41.jpg';
import Seagrove1BWildDunes_42 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_42.jpg';
import Seagrove1BWildDunes_43 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_43.jpg';
import Seagrove1BWildDunes_44 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_44.jpg';
import Seagrove1BWildDunes_45 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_45.jpg';
import Seagrove1BWildDunes_46 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_46.jpg';
import Seagrove1BWildDunes_47 from '../assets/image/1BSeagroveWildDunes/1BSeagroveWildDunes_47.jpg';

function seagrove1BWildDunes() {


    var settings = {
        dots: true,
        //infinite: false,
        arrows: false,
        infinite: true,
        //speed: 5000,
        autoplaySpeed: 5000,
        //autoplay: true,
        autoplay: false,
        adaptiveHeight: false,
        slidesToShow: 1,
        pauseOnFocus: true,
        cssEase: 'ease',
        fade: true,
        pauseOnHover: true,
        swipeToSlide: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="main_div">
            <Header />
            <div className="page-header-with-back-image-title-subtitle" style={{ backgroundImage: `url("${Seagrove1BWildDunes_32}")` }} >
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <div className="page-header-with-back-image-title-subtitle-inner">
                                <h1>1B Seagrove – Wild Dunes</h1>
                                <h3>START FROM <span>$110</span> <sub>/ NIGHT</sub></h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-properties-gallery-outer">
                <Container>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_1} image={Seagrove1BWildDunes_1} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_2} image={Seagrove1BWildDunes_2} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_3} image={Seagrove1BWildDunes_3} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_4} image={Seagrove1BWildDunes_4} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_5} image={Seagrove1BWildDunes_5} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_6} image={Seagrove1BWildDunes_6} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_7} image={Seagrove1BWildDunes_7} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_8} image={Seagrove1BWildDunes_8} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_9} image={Seagrove1BWildDunes_9} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_10} image={Seagrove1BWildDunes_10} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_11} image={Seagrove1BWildDunes_11} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_12} image={Seagrove1BWildDunes_12} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_13} image={Seagrove1BWildDunes_13} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_14} image={Seagrove1BWildDunes_14} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_15} image={Seagrove1BWildDunes_15} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_16} image={Seagrove1BWildDunes_16} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_17} image={Seagrove1BWildDunes_17} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_18} image={Seagrove1BWildDunes_18} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_19} image={Seagrove1BWildDunes_19} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_20} image={Seagrove1BWildDunes_20} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_21} image={Seagrove1BWildDunes_21} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_22} image={Seagrove1BWildDunes_22} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_23} image={Seagrove1BWildDunes_23} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_24} image={Seagrove1BWildDunes_24} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_25} image={Seagrove1BWildDunes_25} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_26} image={Seagrove1BWildDunes_26} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_27} image={Seagrove1BWildDunes_27} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_28} image={Seagrove1BWildDunes_28} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_29} image={Seagrove1BWildDunes_29} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_30} image={Seagrove1BWildDunes_30} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_31} image={Seagrove1BWildDunes_31} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_32} image={Seagrove1BWildDunes_32} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_33} image={Seagrove1BWildDunes_33} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_34} image={Seagrove1BWildDunes_34} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_35} image={Seagrove1BWildDunes_35} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_36} image={Seagrove1BWildDunes_36} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_37} image={Seagrove1BWildDunes_37} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_38} image={Seagrove1BWildDunes_38} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_39} image={Seagrove1BWildDunes_39} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_40} image={Seagrove1BWildDunes_40} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_41} image={Seagrove1BWildDunes_41} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_42} image={Seagrove1BWildDunes_42} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_43} image={Seagrove1BWildDunes_43} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_44} image={Seagrove1BWildDunes_44} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_45} image={Seagrove1BWildDunes_45} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_46} image={Seagrove1BWildDunes_46} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={Seagrove1BWildDunes_47} image={Seagrove1BWildDunes_47} /></div>
                </Container>
            </div>
            <div className="single-property-description">
                <div className="single-property-description-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-title-inner"><h4>Description Of Properties</h4></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-description-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-content-inner">
                                    <p>FYI: As owners and managers we have an awesome responsibility….your vacation. It has got to be as perfect as we can make it. For many people it is the biggest event of the year. On our web site we hit the highlights. It is your responsibility to narrow down your search. We hope we will end up in the top two or three. We have more information to share with you that hopefully will increase the chances on making a good choice whether you book our place or not . Click on: “Ask Owner/Manager a Question” As soon as I hear from you I will do a tentative block of the dates. I will respond not only answering questions but sending detailed information.</p>
                                    <p>No doubt you are on an important mission here looking for the perfect place for your family to vacation or perhaps just a getaway with another couple. Look no further,” a picture is worth a thousand words”. That’s why we’ve included fifty pictures, the maximum allowed on VRBO/HomeAway. The added video (which I’ll email to you later) helps to tie all the pictures together. We take very seriously your vacation. As you compare our place to the others you are considering, it is imperative that you study closely the pictures and video.</p>
                                    <p>Highlights from the pictures and video include: Master bedroom with king size bed and beautiful custom bedding and drapes. The second large bedroom has two queen size beds with your comfort in mind. There is also a queen size sleeper sofa located in the den. The condo has three flat screen TV’s with blue ray DVD players in the den and each bedroom for your convenience. There is ceramic tile and wood flooring throughout the condo. The well appointed custom kitchen and bathrooms all have beautiful granite counter tops. The condo is decorated with beautiful and updated furnishings a step above the rest. We have also included a top of the line washer and dryer for your convenience.</p>
                                    <p>The absolute oceanfront condo is literally steps from the ocean where our guests can enjoy the beautiful view and all the activity on the beach. Our great location puts you only steps away from the ocean, swimming pool, hotel restaurant, coffee shop, Harbor golf course, bike rentals and conference center.</p>
                                    <p>Our cleaning service and condo maintenance service is top notch. We have rigid expectations of them and pay a premium for the best for our guests’ comfort. You can expect a prompt response from either one should you have any questions or concerns during your stay.</p>
                                    <p>We have free WiFi for your use during your stay. In the event you should need technical support we will provide you with all the information necessary so that you can get help quickly.</p>
                                    <p>Our rental rates vary according to season and duration of stay. During the summer months our rentals run Sunday to Sunday. Click on “Rates” above to view a listing of current rates. If you would like an all-inclusive rate that will include your rent, sale’s tax, and cleaning please submit an inquiry by going and clicking on “Contact Owner” an filling out inquiry and “ submit”. Also, please check the “View Calendar” to ensure the rates you are inquiring about are available for rental.</p>
                                    <p>We have made access to your rental easy via a combination number which will be provided after final payment has been received. Wild Dunes is a gated community, therefore, a pass will be available for you at the gate upon arrival.</p>
                                    <p>We look forward to your visit.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="single-property-video">
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <iframe title="1B Seagrove" width="100%" height="686" src="https://www.youtube.com/embed/Y2WcmkCylH0?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-property-amenities">
                <div className="single-property-amenities-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-amenities-title-inner mb-4"><h4>Highlights</h4></div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <div className="single-property-amenities-content-inner-content">
                            <Row>
                                <Col lg={4} md={4} sm={12}><ul><li>Property Type: Villa</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Area: 1,400 Square Feet</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-in: 4:00 PM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-out: 10:00 AM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Maximum Number Of Guests: 6</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Minimum Age Of Primary Renter: 25</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Children Welcome</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Pets Not Allowed</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Non Smoking Only</li></ul></Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={12} sm={12}><ul><li>Location Type: Beach, Resort, Oceanfront</li></ul></Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="single-property-amenities-content">
                    <Container>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities Unit And Ocean Club</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>2&nbsp; Bedrooms, Sleeps 6</li>
                                            <li>2 Bathrooms</li>
                                            <li>1 King Sized Bed, 2 Queen Sized Beds, Pull Out Sofa Bed</li>
                                            <li>Satellite, Cable TV, DVD Player, Wireless Internet</li>
                                            <li>Board Games and Puzzles for rainy days</li>
                                            <li>Video Library</li>
                                            <li>Gorgeous Oceanfront Balcony</li>
                                            <li>Heating and AC </li>
                                            <li>Hair Dryer</li>
                                            <li>Washer and Dryer, Ironing Board</li>
                                            <li>Spacious Living Room With Ocean Views</li>
                                            <li>Oven, Stove, Dishwasher, Refrigerator, Microwave </li>
                                            <li>Toaster, Coffee Maker </li>
                                            <li>Dishes And Cooking Utensils </li>
                                            <li>Dining Room Seating For 6</li>
                                            <li>Towels</li>
                                            <li>Private Parking</li>
                                            <li>Community Pool</li>
                                            <li>Beach Access Steps From Your Door</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities Wild Dunes</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>2 – 18 Hole Championship Golf Courses</li>
                                            <li>Miles of Bike/Running Paths</li>
                                            <li>Shopping And Restaurants In Community</li>
                                            <li>Gated And Private Access </li>
                                            <li>Basketball Court and Playgrounds </li>
                                            <li>Marina, Trailer Parking, Boat Launch</li>
                                        </ul>
                                    </div>
                                    <div className="single-property-amenities-content-inner-title"><h6>Things To Do Charleston</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>Many Amazing Shops And Restaurants</li>
                                            <li>Historical Carriage Tours</li>
                                            <li>Wine Tours</li>
                                            <li>Scenic Drives And Wildlife Viewing</li>
                                            <li>Horseback Riding</li>
                                            <li>Parasailing</li>
                                            <li>Deep Sea Fishing</li>
                                            <li>Speed Boat Adventures And Ghost Tours</li>
                                            <li>High Ropes Course Adventure</li>
                                            <li>Shem Creek Bar And Restaurant Tour</li>
                                            <li>Gibbes Museum Of Art</li>
                                            <li>Haunted Jail Tour</li>
                                            <li>Fort Sumter Tours</li>
                                            <li>South Carolina Aquarium</li>
                                            <li>Patriots Point Naval And Maritime Museum</li>
                                            <li>Mount Pleasant and Holly Beach Piers</li>
                                            <li>Whirlin’ Waters Adventure Waterpark</li>
                                            <li>Isle Of Palms County Park </li>
                                            <li>Splash Island Waterpark</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
            <div className="single-property-testimonials">
                <div className="single-property-testimonials-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-title-inner">
                                    <h4>Testimonials</h4>
                                    <p>What People Say About Us</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-testimonials-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-content-inner">
                                    <div className="single-property-testimonials-slider">
                                        <Slider className="single-property-testimonials-slider" {...settings} >
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Great location and views. This is the cleanest place we have ever stayed at on wild dunes and we come down at least once a year. The unit was larger than what I expected. I really liked that there is good separation between the living room and bedrooms. We have young kids so it was nice to have them in a quiet place while we could still hang out/watch tv in the living room without having to worry about being too loud.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>James</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>We loved getting away at Peter’s place. The view is spectacular. The location was great, too. Close to everything, but away from the busier condos and hotels.lovely condo with a wonderful view of the beach. We had all the space we needed and enjoyed a relaxing time. Isle of Palms is beautiful and we will definitely be back.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Mitzi</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>This was a lovely condo with a wonderful view of the beach. We had all the space we needed and enjoyed a relaxing time. Isle of Palms is beautiful and we will definitely be back.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Melissa F.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Just returned from a wonderful vacation at the Isle of Palms. The location of the condo could not be beat. Sitting on the patio was almost like being on a cruise ship at high tide. Condo was big enough for 6 to be comfortable. Plenty of good restaurants close by and close to Charleston for day trips. Beach is wonderful for walking and collecting shells. Pool was clean and right outside the door. Great time was had by all!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Laura P.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Since this was our first visit to Isle of Palms, I was very concerned about the beach erosion…but I have to say that not having a beach out front at high tide made the view that much more beautiful! We made the short walk to the Grand Pavilion beach area each day. When we were in the room, we had a spectacular view of the dunes, rocks, and waves crashing on the rocks – no people on the beach in front of the condo, so it felt very private. Most definitely the best ocean front view we’ve ever had. The unit was clean, kitchen well stocked, and beds were comfy.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Karla D.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Stayed 8 days at this condo. Wonderful view with the ocean just a few steps from condo. Loved biking the trails on Wild Dunes Property and long walks on the beach. Condo was well equiped and nicely furnished and updated. Short drive to Charleston to shop and eat at great restaurants. The condo offered everything we needed and more. WE would definitely return and recommend to others.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Gene S.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Had this condo for a week. My family really enjoyed our stay here. We enjoyed being so close to the beach, listening to the ocean and watching the waves! It was great being within walking distance of the Wild Dunes boardwalk, hotel and restaurants. Also close to bike rentals, playground and tennis. This unit is well maintained with plenty of room to spreadout. Plenty of kitchen items to help prepare meals and make coffee. Would definitely stay here again</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>David Y.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Our third trip to the villas, first time at this unit. The condo was very comfortable, with updated kitchen and bathrooms. The beds were very comfy, and we loved the deck. A great unobstructed and private view of the ocean. Steps to both the pool and ocean. Very pleased that the Grand Pavillion next door was no longer blaring music, as they had in previous years. We had a peaceful and wonderful stay. Will definitely return!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>PA Visitors</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-price-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className="mb-3">Rates</h3>
                                <p className="left">Week rentals run from Sunday to Sunday. Summer rentals are by the week only. Long weekends available September thru May. The rate is half the weekly rate. No Sales Tax on 3 or more month rentals), $200 Holiday Surcharge (Thanksgiving, Christmas, New Years). Monthly rate (Nov/Mar) $2695, three months (Nov/Mar) $6400. $200 Refundable Security Deposit. 4 pm check in, 10 am check out. Once you make the decision to rent from us you will fill out the form below. I, in turn, will email you a Contract. Sign it and mail back with an Advance Payment. Balance owed will be due 30 days before your rental. Full refund (less $75) in the event of cancellation assuming re-renting is possible.  PLEASE NOTE: There is a 15% Service Fee on all rentals that are less than one week in length.</p>
                                <p>IN THE SUMMER, THESE ARE THE RATES FOR 1B SEAGROVE</p>
                                <p>First two weeks in June    Base Cost $2,395.00    TAXES:  $335.00  Cleaning:  $178.00    TOTAL:  $2,908.00</p>
                                <p>June, July, August    Base Cost $2,595.00    TAXES:  $363.00  Cleaning:  $178.00    TOTAL:  $3,136.00</p>
                                <p>Last two weeks in August    Base Cost $2,395.00    TAXES:  $335.00  Cleaning:  $178.00    TOTAL:  $2,908.00</p>
                                <p>OFF-SEASON NIGHTLY RATES (Three nights minumum)</p>
                                <p>April     Base Cost: $149.00     Taxes: $17.88     Cleaning: $178.00</p>
                                <p>May     Base Cost $139.00     Taxes: $16.68     Cleaning: $178.00</p>
                                <p>September     Base Cost: $139.00     Taxes: $16.68     Cleaning: $178.00</p>
                                <p>October     Base Cost: $125.00     Taxes $15.00     Cleaning: $178.00</p>
                                <p>November thru March     Base Cost: $99.00     Taxes: $11.88     Cleaning: $178.00</p>
                                <h3 className="mt-5">Making a Reservation</h3>
                                <h6 className="mt-2">It is imperative that the information we give you is YOUR full rental cost and availability is tailored to YOUR particular wishes and up to date with our present schedule of bookings.</h6>
                                <h6 className="mt-2">Email me at: <a href="mailto:coleresortrentals@gmail.com">coleresortrentals@gmail.com</a>, writing down the rental location you want to go to and the dates you would like. We will be back in touch ASAP.</h6>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default seagrove1BWildDunes;