import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, NavLink, HashRouter } from 'react-router-dom';
import Home from './view/home';
import About from './view/about';
import Contact from './view/contact';
import OurProperties from './view/ourproperties';
import WildDunesCommunity from './view/wilddunescommunity';
import NorthMyrtleArea from './view/northmyrtlearea';
import OceanClub1504WildDunes from './view/1504oceanclubwilddunes';
import OceanClub1104WildDunes from './view/1104oceanclubwilddunes';
import seagrove1BWildDunes from './view/1bseagrovewilddunes';
import SummerHouse204WildDunes from './view/204summerhousewilddunes';
import PortOCallWildDunes from './view/a301portocallwilddunes';
import TheAshworth1705Oceanfront from './view/1705theashworthoceanfront';
import Wellington403Oceanfront from './view/403wellingtonoceanfront';
import LakeLanierWesternStyleLogHome from './view/lakelanierwesternstyleloghome';
import ContemporaryHomeonBeautifulLakeHartwell from './view/contemporaryhomeonbeautifullakehartwell';
import RusticLogCabinintheWoodsofNorthGeorgia from './view/rusticlogcabininthewoodsofnorthgeorgia';

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<div>
					{/* <Navigation /> */}
					<Switch>
						<Route path="/" component={Home} exact />
						<Route path="/about" component={About} exact />
						<Route path="/contact" component={Contact} exact />
						<Route path="/our-properties" component={OurProperties} exact />
						<Route path="/wild-dunes-community" component={WildDunesCommunity} exact />
						<Route path="/north-myrtle-area" component={NorthMyrtleArea} exact />
						<Route path="/1504-ocean-club-wild-dunes" component={OceanClub1504WildDunes} exact />
						<Route path="/1104-ocean-club-wild-dunes" component={OceanClub1104WildDunes} exact />
						<Route path="/1b-seagrove-wild-dunes" component={seagrove1BWildDunes} exact />
						<Route path="/204-summer-house-wild-dunes" component={SummerHouse204WildDunes} exact />
						<Route path="/a-301-port-o-call-wild-dunes" component={PortOCallWildDunes} exact />
						<Route path="/1705-the-ashworth-oceanfront" component={TheAshworth1705Oceanfront} exact />
						<Route path="/403-wellington-oceanfront" component={Wellington403Oceanfront} exact />
						<Route path="/lake-lanier-western-style-log-home" component={LakeLanierWesternStyleLogHome} exact />
						<Route path="/contemporary-home-on-beautiful-lake-hartwell" component={ContemporaryHomeonBeautifulLakeHartwell} exact />
						<Route path="/rustic-log-cabin-in-the-woods-of-north-georgia" component={RusticLogCabinintheWoodsofNorthGeorgia} exact />
					</Switch>
				</div>
			</BrowserRouter>
		);
	}
}

export default App;
