import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../common/footer";
import Header from "../common/header";

import about_banner from '../assets/image/about_banner.jpg';
import DavidandFam from '../assets/image/DavidandFam.jpg';
import PeterandJudyandMacey from '../assets/image/PeterandJudyandMacey.jpg';
import PeterandDavid from '../assets/image/PeterandDavid.jpg';
function About() {
    return (
        <div className="main_div">
            <Header />
            <div className="main">
                <div className="page-header-with-back-image-title-subtitle with-center-border" style={{ backgroundImage: `url("${about_banner}")` }}>
                    <Container>
                        <Row>
                            <Col xl={3} lg={2} md={12} sm={12}></Col>
                            <Col xl={6} lg={8} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                                <div className="page-header-with-back-image-title-subtitle-inner text-center">
                                    <h1>About Us</h1>
                                </div>
                            </Col>
                            <Col xl={3} lg={2} md={12} sm={12}></Col>
                        </Row>
                    </Container>
                </div>
                <div className="about-company-history">
                    <div className="about-company-history-title">
                        <Container>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12}>
                                    <center><img src={DavidandFam} alt="DavidandFam" /></center>
                                    <h4 class="mt-2"><center>David Bell</center></h4>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="about-company-history-content">
                        <Container>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12}>
                                    <div className="about-company-history-content-inner">
                                        <p>Peter has been in the vacation home rental business since 1967. David has been in the vacation rental business since 2017. However, their friendship goes back decades. David first met Peter at age 17 when he went out west as a participant in TCTW (Teens Camping Tour of the West). Peter was founder and owner of this fantastic organization that began in 1967 and is still in operation today. Check out the website: <a href="https://www.tctw.com/" target="_blank" rel="noopener noreferrer">https://www.tctw.com/</a>. It is a 23-day camping experience that starts in North Carolina and goes to most of the National Parks out West and back. It is not only a tour of the West – but a way that teens can discover their unique God given talents so they can make a positive difference in their corner of the world. Most will reach a new level of self-confidence and independence.</p>
                                        <p>As a teen, David had many talents and loved traveling out west so naturally, Peter invited him to return west the following summer! No other participant had ever been on two western trips with TCTW. Peter took teenagers on many different trips and David followed. Trips included sailing to the Bahamas after Christmas, rafting down the Colorado River through the Grand Canyon, and even traveling around the world while camping in a tent. David’s Mom used to say “If Peter took a trip to hell, David would sign up!”</p>
                                        <p>After college, David worked with TCTW as a counselor/driver for the Western and Bahamas trips for over ten years. While on one of the western trips, David and his first love Janet, were married at the Chapel of the Transfiguration in Wyoming. Family members and close friends arrived from Atlanta while Peter served as best man. The “tour kids” were special guests for this special event. Janet is still not happy, that after the short honeymoon of only one night, David had to get back in the van and continue his trip as a counselor for TCTW. David, however, was more upset that Harrison Ford was staying at the same Dude Ranch that evening but the owners decided not to interrupt the honeymoon couple while everyone else staying there got to meet him!</p>
                                        <p>David continued his love for travel and has been to Peru twice, to the Amazon in Peru and Brazil, driven from Atlanta to Belize in an old VW bug and taken many trips to Canada, and Europe. He is a photographer, printmaker, and papermaker who enjoys combining his love of travel with making art. David’s work has been shown in many museums and art galleries around the southeast. He is currently writing a book about Peter and David’s trip with a small group of college students who traveled around the world while camping in the tent. Highlights included sleeping by the pyramids of Giza, being chased by a tank in the Golan Heights, and staying with a tribe in Africa. David and wife Janet have instilled that love of travel in their two children, Greg, a graduate of The Savannah College of Art and Design, (SCAD) and Jamie, a high school senior. </p>
                                        <p>Aside from being a tour director, Peter has always managed properties – some his own and some for other owners. He has managed properties located at Beech Mountain, NC, Wintergreen Resort VA, Myrtle Beach, North Myrtle Beach SC, Wild Dunes Resort SC, Beaufort SC and St. Simons GA. He and his wife, Judy, have enjoyed renovating and decorating their properties over the years which enhanced both property value and rent ability.</p>
                                        <p>From Atlanta in 2017, David started a property management company and in late 2018 David and Peter merged to form a business partnership called Red Ground,LLC. David completed his South Carolina license as a Property Manager in Charge in 2019, but he still has much to learn from his best friend Peter. It is such a blessing that these two ole’ friends can work together every day to provide their owners unique expertise and value by renting their units to people the world over who will have a vacation to remember!</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="about-company-history-content">
                        <Container>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12}>
                                    <h4 className="mt-5 mb-2"><center>Good Friends Since 1975!</center></h4>
                                    <center><img src={PeterandDavid} alt="PeterandDavid" /></center>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default About;