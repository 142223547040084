import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../common/footer";
import Header from "../common/header";

import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TheAshworthOceanfront_1 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_1.jpg';
import TheAshworthOceanfront_2 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_2.jpg';
import TheAshworthOceanfront_3 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_3.jpg';
import TheAshworthOceanfront_4 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_4.jpg';
import TheAshworthOceanfront_5 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_5.jpg';
import TheAshworthOceanfront_6 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_6.jpg';
import TheAshworthOceanfront_7 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_7.jpg';
import TheAshworthOceanfront_8 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_8.jpg';
import TheAshworthOceanfront_9 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_9.jpg';
import TheAshworthOceanfront_10 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_10.jpg';
import TheAshworthOceanfront_11 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_11.jpg';
import TheAshworthOceanfront_12 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_12.jpg';
import TheAshworthOceanfront_13 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_13.jpg';
import TheAshworthOceanfront_14 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_14.jpg';
import TheAshworthOceanfront_15 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_15.jpg';
import TheAshworthOceanfront_16 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_16.jpg';
import TheAshworthOceanfront_17 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_17.jpg';
import TheAshworthOceanfront_18 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_18.jpg';
import TheAshworthOceanfront_19 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_19.jpg';
import TheAshworthOceanfront_20 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_20.jpg';
import TheAshworthOceanfront_21 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_21.jpg';
import TheAshworthOceanfront_22 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_22.jpg';
import TheAshworthOceanfront_23 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_23.jpg';
import TheAshworthOceanfront_24 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_24.jpg';
import TheAshworthOceanfront_25 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_25.jpg';
import TheAshworthOceanfront_26 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_26.jpg';
import TheAshworthOceanfront_27 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_27.jpg';
import TheAshworthOceanfront_28 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_28.jpg';
import TheAshworthOceanfront_29 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_29.jpg';
import TheAshworthOceanfront_30 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_30.jpg';
import TheAshworthOceanfront_31 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_31.jpg';
import TheAshworthOceanfront_32 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_32.jpg';
import TheAshworthOceanfront_33 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_33.jpg';
import TheAshworthOceanfront_34 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_34.jpg';
import TheAshworthOceanfront_35 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_35.jpg';
import TheAshworthOceanfront_36 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_36.jpg';
import TheAshworthOceanfront_37 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_37.jpg';
import TheAshworthOceanfront_38 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_38.jpg';
import TheAshworthOceanfront_39 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_39.jpg';
import TheAshworthOceanfront_40 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_40.jpg';
import TheAshworthOceanfront_41 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_41.jpg';
import TheAshworthOceanfront_42 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_42.jpg';
import TheAshworthOceanfront_43 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_43.jpg';
import TheAshworthOceanfront_44 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_44.jpg';
import TheAshworthOceanfront_45 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_45.jpg';
import TheAshworthOceanfront_46 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_46.jpg';
import TheAshworthOceanfront_47 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_47.jpg';
import TheAshworthOceanfront_48 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_48.jpg';
import TheAshworthOceanfront_49 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_49.jpg';
import TheAshworthOceanfront_50 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_50.jpg';
import TheAshworthOceanfront_51 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_51.jpg';
import TheAshworthOceanfront_52 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_52.jpg';
import TheAshworthOceanfront_53 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_53.jpg';
import TheAshworthOceanfront_54 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_54.jpg';
import TheAshworthOceanfront_55 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_55.jpg';
import TheAshworthOceanfront_56 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_56.jpg';
import TheAshworthOceanfront_57 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_57.jpg';
import TheAshworthOceanfront_58 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_58.jpg';
import TheAshworthOceanfront_59 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_59.jpg';
import TheAshworthOceanfront_60 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_60.jpg';
import TheAshworthOceanfront_61 from '../assets/image/1705TheAshworthOceanfront/1705_The_Ashworth_Oceanfront_61.jpg';

function TheAshworth1705Oceanfront() {


    var settings = {
        dots: true,
        //infinite: false,
        arrows: false,
        infinite: true,
        //speed: 5000,
        autoplaySpeed: 5000,
        //autoplay: true,
        autoplay: false,
        adaptiveHeight: false,
        slidesToShow: 1,
        pauseOnFocus: true,
        cssEase: 'ease',
        fade: true,
        pauseOnHover: true,
        swipeToSlide: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="main_div">
            <Header />
            <div className="page-header-with-back-image-title-subtitle" style={{ backgroundImage: `url("${TheAshworthOceanfront_1}")` }} >
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <div className="page-header-with-back-image-title-subtitle-inner">
                                <h1>1705 The Ashworth – Oceanfront</h1>
                                <h3>START FROM <span>$167</span> <sub>/ NIGHT</sub></h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-properties-gallery-outer">
                <Container>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_1} image={TheAshworthOceanfront_1} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_2} image={TheAshworthOceanfront_2} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_3} image={TheAshworthOceanfront_3} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_4} image={TheAshworthOceanfront_4} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_5} image={TheAshworthOceanfront_5} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_6} image={TheAshworthOceanfront_6} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_7} image={TheAshworthOceanfront_7} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_8} image={TheAshworthOceanfront_8} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_9} image={TheAshworthOceanfront_9} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_10} image={TheAshworthOceanfront_10} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_11} image={TheAshworthOceanfront_11} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_12} image={TheAshworthOceanfront_12} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_13} image={TheAshworthOceanfront_13} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_14} image={TheAshworthOceanfront_14} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_15} image={TheAshworthOceanfront_15} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_16} image={TheAshworthOceanfront_16} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_17} image={TheAshworthOceanfront_17} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_18} image={TheAshworthOceanfront_18} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_19} image={TheAshworthOceanfront_19} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_20} image={TheAshworthOceanfront_20} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_21} image={TheAshworthOceanfront_21} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_22} image={TheAshworthOceanfront_22} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_23} image={TheAshworthOceanfront_23} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_24} image={TheAshworthOceanfront_24} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_25} image={TheAshworthOceanfront_25} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_26} image={TheAshworthOceanfront_26} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_27} image={TheAshworthOceanfront_27} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_28} image={TheAshworthOceanfront_28} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_29} image={TheAshworthOceanfront_29} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_30} image={TheAshworthOceanfront_30} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_31} image={TheAshworthOceanfront_31} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_32} image={TheAshworthOceanfront_32} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_33} image={TheAshworthOceanfront_33} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_34} image={TheAshworthOceanfront_34} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_35} image={TheAshworthOceanfront_35} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_36} image={TheAshworthOceanfront_36} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_37} image={TheAshworthOceanfront_37} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_38} image={TheAshworthOceanfront_38} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_39} image={TheAshworthOceanfront_39} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_40} image={TheAshworthOceanfront_40} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_41} image={TheAshworthOceanfront_41} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_42} image={TheAshworthOceanfront_42} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_43} image={TheAshworthOceanfront_43} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_44} image={TheAshworthOceanfront_44} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_45} image={TheAshworthOceanfront_45} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_46} image={TheAshworthOceanfront_46} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_47} image={TheAshworthOceanfront_47} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_48} image={TheAshworthOceanfront_48} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_49} image={TheAshworthOceanfront_49} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_50} image={TheAshworthOceanfront_50} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_51} image={TheAshworthOceanfront_51} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_52} image={TheAshworthOceanfront_52} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_53} image={TheAshworthOceanfront_53} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_54} image={TheAshworthOceanfront_54} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_55} image={TheAshworthOceanfront_55} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_56} image={TheAshworthOceanfront_56} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_57} image={TheAshworthOceanfront_57} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_58} image={TheAshworthOceanfront_58} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_59} image={TheAshworthOceanfront_59} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_60} image={TheAshworthOceanfront_60} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={TheAshworthOceanfront_61} image={TheAshworthOceanfront_61} /></div>
                </Container>
            </div>
            <div className="single-property-description">
                <div className="single-property-description-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-title-inner"><h4>Description Of Properties</h4></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-description-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-content-inner">
                                    {/*<p>FYI: As owners and managers we have an awesome responsibility….your vacation. It has got to be as perfect as we can make it. For many people it is the biggest event of the year. On our web site we hit the highlights. It is your responsibility to narrow down your search. We hope we will end up in the top two or three. We have more information to share with you that hopefully will increase the chances on making a good choice whether you book our place or not . Click on: “Ask Manager a Question” As soon as I hear from you I will do a tentative block of the dates. I will respond not only answering questions but sending detailed information.</p>
                                    <p>You’re sitting in the catbird seat in more ways than you can shake a stick at, and what a view you have while sitting there! Look below and watch waves roll in; look ahead (we’re on the 17th floor) and on a clear day you can see France. So what am I referring to when I say the ‘catbird seat? You have a tremendous selection of outstanding condominiums to choose from. In many ways you cannot go wrong. Probably the most important thing to you is the price of your rental. Let me say quite simply, shop ’til you drop. Find a price more reasonable than ours, and I will beat it; assuming apples to apples.</p>
                                    <p>As you read through the other listings that you may have an interest in did anyone mention their cleaning service? Hands down, ours is best. On the very off chance you find a speck of dust or a grain of sand, you will be given their phone number in advance. Call them to come re-clean. We have an advantage over other places where different cleaners parade through. We have the same housekeeper week to week and ours takes a personal interest in our place. We reward them well for the extra care they give. We also make it very easy to clean like having tile flooring in all the traffic areas (the bedrooms are carpeted). You will not start your vacation off on the wrong foot if we have anything to say about it.</p>
                                    <p>And while we are talking about the wrong foot, check-in (4 PM) and check-out (10 AM) is a breeze; all done by mail and phone. Nothing starts off a vacation worse than a long check-in procedure. Certainly you have done that before and know the pain. Go to Google Maps: 102 N. Ocean Blvd, North Myrtle Beach, SC(South Carolina….don’t put in France by accident!).</p>
                                    <p>The Ashworth is similar to about a dozen high rises in the North Myrtle Beach area: oceanfront, indoor swimming pool, outdoor pool, kiddie pool, mega hot tub, lazy river, fitness room, ample on site parking. There are two very subtle differences between ours and all the others. It might not make a lot of difference to you but it did to us. Look at the picture to the upper left; to the right and left of that building are low structures which means the beach is less crowded. Going north on the beach is less populated because you enter an area where there are only houses. The second difference is our proximity to downtown of the city of Ocean Drive, an easy walk to lots of shopping and eateries. None of the other buildings are so conveniently located.</p>
                                    <p>You might think of something to add to the condo before you go that would enhance your stay. I once had someone suggest a bread maker. We put one in. It rusted out before anyone made the first slice of bread. We did not learn our lesson. Every suggestion is a command to us.</p>
                                    <p>My first priority is to make sure we meet your needs. The pictures certainly help to explain things. My rambling on is of some good as well, or at least I’d like to think so. But to tell you the truth…..and I know you like it when I do! I think it is the comments of our past renters that really add to your education regarding how suitable we will be for you. Please take a moment to read them. It will give you even a better feel for the place.</p>
                                    <p>There are fifty pictures on the web site. That is the limit VRBO will allow. You know what they say: ‘a picture is worth a thousand words’. EQUALLY AS IMPORTANT AS PICTURES! Please review the video I will be emailing to you. It helps to tie in all the still pictures. I have more detailed information that will be of interest I’ll be happy to email you. It will serve you well to visit the other links below to learn more about the area. I’d love to hear your questions too. Please check the calendar and our rates before emailing. They are both up to date. Rates are NOT subjected to change.</p>
                                    <p>HIGHLIGHTS IN REVIEW: 1) If you are requesting a lower price than our posted rate, then we need the web link to the lower priced one you are considering. 2) Check calendar for availability before submitting an inquiry. 3) Review the pictures and video. 4) Read comments by past guests. 5) Thank you so much for considering our place.</p>
                                    <p>I usually respond to an inquiry within 24 hours of receipt. If you haven’t heard from me within that frame period, check the calendar for availability. I usually don’t respond if we don’t have availability. Next try your spam. Sometimes I get caught there.</p>
                                    <p>ONCE YOU SUBMIT AN INQUIRY, YOUR FIRST COMMUNICATION WILL COME FROM VRBO/HOMEAWAY. VERY IMPORTANT TO READ IT CAREFULLY ESPECIALLY IN REGARD TO SECURITY.</p> */}
                                    <p>When we say you can see France on a clear day, we mean you will have an amazing ocean view.  The condo is on the 17th floor with an amazing view of the ocean.  </p>
                                    <p>The Ashworth is like a dozen high rises in the North Myrtle Beach area: oceanfront, indoor swimming pool, outdoor pool, kiddie pool, hot tub, lazy river, fitness room, ample onsite covered parking, overflow parking if you need it.  </p>
                                    <p>There are two very subtle differences between our condo and all the others. This condo is on the north end of the beach which is less populated and less crowded than the rest.  The second difference is our proximity to the downtown area of thecity of North Myrtle Beach, an easy walk to lots of shopping, summer concerts,  and eateries. None of the other buildings are so conveniently located.</p>
                                    <p>Our maintenance and cleaning crews are amazing but if you find something that is not quite right, we will provide you with contact information so you can get satisfaction ASAP!</p>
                                    <p>As property managers, we realize that we have an awesome responsibility; your vacation. Studies show that Americans are vacation deprived and not taking enough time for themselves or their families. A vacation getaway could be the perfect prescription for job burnout. You need and deserve the time off, take it. Your vacation must be as perfect and as meaningful as we can make it.</p> 
                                    <p>Please contact us with any questions and book this amazing condo today!</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="single-property-video">
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <iframe title="1705 The Ashworth" width="100%" height="686" src="https://www.youtube.com/embed/2vOUN0_p4sM?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-property-amenities">
                <div className="single-property-amenities-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-amenities-title-inner mb-4"><h4>Highlights</h4></div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <div className="single-property-amenities-content-inner-content">
                            <Row>
                                <Col lg={4} md={4} sm={12}><ul><li>Property Type: Condo</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Area: 1600 Square Feet</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-in: 4:00 PM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-out: 10:00 AM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Maximum Number Of Guests: 10</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Minimum Age Of Primary Renter: 25</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Children Welcome</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Pets Not Allowed</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Non Smoking Only</li></ul></Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={12} sm={12}><ul><li>Location Type: Oceanfront, Beach, Waterfront, Resort</li></ul></Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="single-property-amenities-content">
                    <Container>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities Unit 1705</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>4&nbsp; Bedrooms, Sleeps 10</li>
                                            <li>3 Bathrooms</li>
                                            <li>1 King Sized Bed, 2 Queen Sized Beds</li>
                                            <li>2 Double Beds, Child Bed (Pac N Play)</li>
                                            <li>Satellite, Cable TV, DVD Player, Wireless Internet</li>
                                            <li>VCR, Stereo, CD Player</li>
                                            <li>Video Library</li>
                                            <li>17th Floor Balcony With Stunning Views</li>
                                            <li>Heating and AC </li>
                                            <li>Hair Dryer, Toilet Paper </li>
                                            <li>Washer and Dryer, Ironing Board </li>
                                            <li>Ceiling Fans</li>
                                            <li>Security System and Telephone</li>
                                            <li>Oven, Stove, Dishwasher, Refrigerator, Microwave </li>
                                            <li>Blender, Toaster, Coffee Maker, Coffee Grinder</li>
                                            <li>Dishes And Cooking Utensils </li>
                                            <li>Ice Maker And Lobster Pot</li>
                                            <li>Linens Provided </li>
                                            <li>Elevator And Covered Parking</li>
                                            <li>Dining Area</li>
                                            <li>Child’s Highchair</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities The Ashworth</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>Oceanfront Pool</li>
                                            <li>Covered Parking</li>
                                            <li>Fitness Center</li>
                                            <li>Hot Tub</li>
                                            <li>Kiddie Pool</li>
                                            <li>Covered Pool For The Winter</li>
                                            <li>Outdoor Grills</li>
                                            <li>Walkway To The Beach</li>
                                            <li>Steps From North Myrtle Main Street</li>
                                        </ul>
                                    </div>
                                    <div className="single-property-amenities-content-inner-title"><h6>Things To Do North Myrtle Beach</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>Downtown North Myrtle Beach Steps Away</li>
                                            <li>Shops and Restaurants</li>
                                            <li>Alligator Adventure</li>
                                            <li>Barefoot Landing</li>
                                            <li>Horseback Riding</li>
                                            <li>Parasailing</li>
                                            <li>Deep Sea Fishing</li>
                                            <li>Barefoot Landing</li>
                                            <li>The Most Golf Courses In Any Area Of The US</li>
                                            <li>Tennis</li>
                                            <li>Putt Putt Golf</li>
                                            <li>Alabama Theatre</li>
                                            <li>Duplin Winery</li>
                                            <li>Tiger’s Preservation Station</li>
                                            <li>Cherry Grove Fishing Pier</li>
                                            <li>House Of Blues</li>
                                            <li>Tangier Outlets Shopping</li>
                                            <li>North Myrtle Beach Historical Museum</li>
                                            <li>Go APE Zipline Adventures</li>
                                            <li>Myrtle Beach Speedway</li>
                                            <li>Myrtle Beach Waterpark</li>
                                            <li>Boardwalk Shopping and Restaurants</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
            <div className="single-property-testimonials">
                <div className="single-property-testimonials-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-title-inner">
                                    <h4>Testimonials</h4>
                                    <p>What People Say About Us</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-testimonials-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-content-inner">
                                    <div className="single-property-testimonials-slider">
                                        <Slider className="single-property-testimonials-slider" {...settings} >
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Great space with beautiful views! Plenty of room for family of seven. Enjoyed the pool, hot tub, but and walks on the beach! Close to everything ! There was an issue with the door code when we arrived but it was quickly resolved.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Mark</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>My family started a family vacation as a tradition 11 years ago and this is my family’s favorite accommodations thus far. I would highly recommend.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Faith</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>The condo was very homey and clean. The host were quick to answer questions if the arose. Would definitely love to stay again.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Ashley</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-price-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className="mb-3">Rates</h3>
                                <p className="left">Three months $2995 (no sales tax)</p>
                                <p className="left">4 pm check in, 10 am check out. June thru August we only rent by the week, and it is Saturday to Saturday. The rest of the year you can rent a long weekend (3 nights), and the rate will be half the weekly rate. Inquire about 4-to-6-day rate. When renting by the week September thru May we prefer it not be Saturday to Saturday. There is $100 surcharge for 9 to 10 people (limit 10), $200 fee for Holidays (Thanksgiving, Christmas, & New Year’s) and SOS periods. *There is a 12% Sales Tax (State, County and City), Housekeeping fee of $195, cleaning, linens/towels plus bed making and refundable $200 Security Deposit. In the event of a cancellation all money will be refunded less a $75 fee and us re-renting the condo.</p>
                                <p>IN THE SUMMER, THESE ARE THE RATES FOR 1705 ASHWORTH</p>
                                <p>Summer: Weekly Base Cost $2,693.00 TAXES: $324.00 Cleaning: $195.00 TOTAL: $3,212.00</p>
                                <p>OFF-SEASON NIGHTLY RATES</p>
                                <p>Spring Base Cost: $221.00 Taxes: $27 Cleaning: $195.00</p>
                                <p>Fall Base Cost: $176.00 Taxes $21 Cleaning $195.00</p>
                                <p>Winter Base Cost: $154.00 Taxes $19 Cleaning $195.00</p>
                                <p>Monthly rentals available for the months of November thru March $1,495 Three months $2,995 (no sales tax)</p>

                                <h3 className="mt-5">Making a Reservation</h3>
                                <h6 className="mt-2">It is imperative that the information we give you is YOUR full rental cost and availability is tailored to YOUR particular wishes and up to date with our present schedule of bookings.</h6>
                                <h6 className="mt-2">Email me at: <a href="mailto:coleresortrentals@gmail.com">coleresortrentals@gmail.com</a>, writing down the rental location you want to go to and the dates you would like. We will be back in touch ASAP.</h6>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default TheAshworth1705Oceanfront;