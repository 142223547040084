import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../common/footer";
import Header from "../common/header";

import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import A301PortOCallWildDunes_1 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_1.jpg';
import A301PortOCallWildDunes_2 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_2.jpg';
import A301PortOCallWildDunes_3 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_3.jpg';
import A301PortOCallWildDunes_4 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_4.jpg';
import A301PortOCallWildDunes_5 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_5.jpg';
import A301PortOCallWildDunes_6 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_6.jpg';
import A301PortOCallWildDunes_7 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_7.jpg';
import A301PortOCallWildDunes_8 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_8.jpg';
import A301PortOCallWildDunes_9 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_9.jpg';
import A301PortOCallWildDunes_10 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_10.jpg';
import A301PortOCallWildDunes_11 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_11.jpg';
import A301PortOCallWildDunes_12 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_12.jpg';
import A301PortOCallWildDunes_13 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_13.jpg';
import A301PortOCallWildDunes_14 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_14.jpg';
import A301PortOCallWildDunes_15 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_15.jpg';
import A301PortOCallWildDunes_16 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_16.jpg';
import A301PortOCallWildDunes_17 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_17.jpg';
import A301PortOCallWildDunes_18 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_18.jpg';
import A301PortOCallWildDunes_19 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_19.jpg';
import A301PortOCallWildDunes_20 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_20.jpg';
import A301PortOCallWildDunes_21 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_21.jpg';
import A301PortOCallWildDunes_22 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_22.jpg';
import A301PortOCallWildDunes_23 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_23.jpg';
import A301PortOCallWildDunes_24 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_24.jpg';
import A301PortOCallWildDunes_25 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_25.jpg';
import A301PortOCallWildDunes_26 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_26.jpg';
import A301PortOCallWildDunes_27 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_27.jpg';
import A301PortOCallWildDunes_28 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_28.jpg';
import A301PortOCallWildDunes_29 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_29.jpg';
import A301PortOCallWildDunes_30 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_30.jpg';
import A301PortOCallWildDunes_31 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_31.jpg';
import A301PortOCallWildDunes_32 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_32.jpg';
import A301PortOCallWildDunes_33 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_33.jpg';
import A301PortOCallWildDunes_34 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_34.jpg';
import A301PortOCallWildDunes_35 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_35.jpg';
import A301PortOCallWildDunes_36 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_36.jpg';
import A301PortOCallWildDunes_37 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_37.jpg';
import A301PortOCallWildDunes_38 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_38.jpg';
import A301PortOCallWildDunes_39 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_39.jpg';
import A301PortOCallWildDunes_40 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_40.jpg';
import A301PortOCallWildDunes_41 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_41.jpg';
import A301PortOCallWildDunes_42 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_42.jpg';
import A301PortOCallWildDunes_43 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_43.jpg';
import A301PortOCallWildDunes_44 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_44.jpg';
import A301PortOCallWildDunes_45 from '../assets/image/A301PortOCallWildDunes/A301PortOCallWildDunes_45.jpg';

function PortOCallWildDunes() {


    var settings = {
        dots: true,
        //infinite: false,
        arrows: false,
        infinite: true,
        //speed: 5000,
        autoplaySpeed: 5000,
        //autoplay: true,
        autoplay: false,
        adaptiveHeight: false,
        slidesToShow: 1,
        pauseOnFocus: true,
        cssEase: 'ease',
        fade: true,
        pauseOnHover: true,
        swipeToSlide: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="main_div">
            <Header />
            <div className="page-header-with-back-image-title-subtitle" style={{ backgroundImage: `url("${A301PortOCallWildDunes_36}")` }} >
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <div className="page-header-with-back-image-title-subtitle-inner">
                                <h1>A-301 Port O’ Call – Wild Dunes</h1>
                                <h3>START FROM <span>$110</span> <sub>/ NIGHT</sub></h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-properties-gallery-outer">
                <Container>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_1} image={A301PortOCallWildDunes_1} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_2} image={A301PortOCallWildDunes_2} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_3} image={A301PortOCallWildDunes_3} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_4} image={A301PortOCallWildDunes_4} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_5} image={A301PortOCallWildDunes_5} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_6} image={A301PortOCallWildDunes_6} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_7} image={A301PortOCallWildDunes_7} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_8} image={A301PortOCallWildDunes_8} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_9} image={A301PortOCallWildDunes_9} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_10} image={A301PortOCallWildDunes_10} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_11} image={A301PortOCallWildDunes_11} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_12} image={A301PortOCallWildDunes_12} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_13} image={A301PortOCallWildDunes_13} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_14} image={A301PortOCallWildDunes_14} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_15} image={A301PortOCallWildDunes_15} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_16} image={A301PortOCallWildDunes_16} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_17} image={A301PortOCallWildDunes_17} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_18} image={A301PortOCallWildDunes_18} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_19} image={A301PortOCallWildDunes_19} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_20} image={A301PortOCallWildDunes_20} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_21} image={A301PortOCallWildDunes_21} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_22} image={A301PortOCallWildDunes_22} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_23} image={A301PortOCallWildDunes_23} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_24} image={A301PortOCallWildDunes_24} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_25} image={A301PortOCallWildDunes_25} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_26} image={A301PortOCallWildDunes_26} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_27} image={A301PortOCallWildDunes_27} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_28} image={A301PortOCallWildDunes_28} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_29} image={A301PortOCallWildDunes_29} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_30} image={A301PortOCallWildDunes_30} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_31} image={A301PortOCallWildDunes_31} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_32} image={A301PortOCallWildDunes_32} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_33} image={A301PortOCallWildDunes_33} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_34} image={A301PortOCallWildDunes_34} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_35} image={A301PortOCallWildDunes_35} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_36} image={A301PortOCallWildDunes_36} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_37} image={A301PortOCallWildDunes_37} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_38} image={A301PortOCallWildDunes_38} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_39} image={A301PortOCallWildDunes_39} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_40} image={A301PortOCallWildDunes_40} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_41} image={A301PortOCallWildDunes_41} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_42} image={A301PortOCallWildDunes_42} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_43} image={A301PortOCallWildDunes_43} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_44} image={A301PortOCallWildDunes_44} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={A301PortOCallWildDunes_45} image={A301PortOCallWildDunes_45} /></div>
                </Container>
            </div>
            <div className="single-property-description">
                <div className="single-property-description-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-title-inner"><h4>Description Of Properties</h4></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-description-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-content-inner">
                                    <p>FYI: As owners and managers we have an awesome responsibility….your vacation. It has got to be as perfect as we can make it. For many people it is the biggest event of the year. On our web site we hit the highlights. It is your responsibility to narrow down your search. We hope we will end up in the top two or three. We have more information to share with you that hopefully will increase the chances on making a good choice whether you book our place or not . Click on: “Ask Owner/Manager a Question” As soon as I hear from you I will do a tentative block of the dates. I will respond not only answering questions but sending detailed information.</p>
                                    <p>Complete renovation- let the pictures and video tell the story! Many of the units at Port O’ Call have gone through renovations, but few, if any, have had renovations as first-class and extensive as this top-floor unit, A 301. Simply stated, the owners want this unit to serve as an extension of their home. The renovations and interior design were made not to maximize the return on their investment from rental income, but to maximize the pleasure and experience of visiting Wild Dunes. Space doesn’t permit listing all of the improvements, but imagine staying in what is for all practical purposes a brand new unit.</p>
                                    <p>Hardwood floors</p>
                                    <p>New custom cabinetry</p>
                                    <p>Granite in kitchen, bathroom and wet bar</p>
                                    <p>Raised ceilings in foyer and kitchen</p>
                                    <p>All new furniture, rugs and art</p>
                                    <p>Stainless steel appliances</p>
                                    <p>New interior and exterior doors</p>
                                    <p>New HVAC ductwork</p>
                                    <p>Custom shutters</p>
                                    <p>If you’ve stayed in a Port O’ Call unit before, you will instantly recognize the difference when you walk in the door of A 301. If you haven’t stayed at Port O’ Call, be prepared for a treat. This luxury unit is designed to deliver an exceptional experience beyond your expectations. There is nothing the owners haven’t given complete and detailed attention to. This is a top-floor end unit, so you won’t hear the sounds of other guests that come with a first or second floor unit or an interior unit.</p>
                                    <p>Check out the pictures and the video…you’ll see what we’re talking about. But experience the luxurious furnishings and the views of the ocean and golf course first hand. You won’t be disappointed!</p>
                                    <p>Please Note:  There is absolutely NO SMOKING allowed in this condo or on any of the porches.  Thank you for your understanding!</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="single-property-video">
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <iframe title="Port of Call A301 New" width="100%" height="686" src="https://www.youtube.com/embed/bSVh8p7dDrk?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-property-amenities">
                <div className="single-property-amenities-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-amenities-title-inner mb-4"><h4>Highlights</h4></div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <div className="single-property-amenities-content-inner-content">
                            <Row>
                                <Col lg={4} md={4} sm={12}><ul><li>Property Type: Condo</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Area: 9500 Square Feet</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-in: 4:00 PM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-out: 10:00 AM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Maximum Number Of Guests: 2</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Minimum Age Of Primary Renter: 25</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Not Suitable For Children</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Parties/Events NOT ALLOWED</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Pets Not Allowed</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Non Smoking Only</li></ul></Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={12} sm={12}><ul><li>Location Type: Golf Course View, Ocean View, Beach, Resort</li></ul></Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="single-property-amenities-content">
                    <Container>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Port O’ Call Amenities</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>1&nbsp; Bedrooms, Sleeps 2</li>
                                            <li>1 Bathroom</li>
                                            <li>1 King Sized Bed</li>
                                            <li>Satellite, Cable TV, DVD Player, Wireless Internet</li>
                                            <li>VCR, Stereo, CD Player</li>
                                            <li>Gorgeous Loft Balcony and Screened In Balcony</li>
                                            <li>Heating and AC </li>
                                            <li>Hair Dryer, Telephone</li>
                                            <li>Washer and Dryer, Ironing Board </li>
                                            <li>Oven, Stove, Dishwasher, Refrigerator, Microwave </li>
                                            <li>Toaster, Coffee Maker, Pantry Items</li>
                                            <li>Dishes And Cooking Utensils </li>
                                            <li>Towels And Linens Provided </li>
                                            <li>Elevator And Private Parking</li>
                                            <li>Outdoor Grill</li>
                                            <li>Outdoor Pool At Port Of Call</li>
                                            <li>Shared Outdoor Hot Tub</li>
                                            <li>Walkway To The Beach</li>
                                            <li>Wheelchair Accesible</li>
                                            <li>Long Term Renters Welcome</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities Wild Dunes</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>2 – 18 Hole Championship Golf Courses</li>
                                            <li>Miles of Bike/Running Paths</li>
                                            <li>Shopping And Restaurants In Community</li>
                                            <li>Gated And Private Access </li>
                                            <li>Basketball Court and Playgrounds </li>
                                            <li>Marina, Trailer Parking, Boat Launch</li>
                                        </ul>
                                    </div>
                                    <div className="single-property-amenities-content-inner-title"><h6>Things To Do Charleston</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>Many Amazing Shops And Restaurants</li>
                                            <li>Historical Carriage Tours</li>
                                            <li>Wine Tours</li>
                                            <li>Scenic Drives And Wildlife Viewing</li>
                                            <li>Horseback Riding</li>
                                            <li>Parasailing</li>
                                            <li>Deep Sea Fishing</li>
                                            <li>Speed Boat Adventures And Ghost Tours</li>
                                            <li>High Ropes Course Adventure</li>
                                            <li>Shem Creek Bar And Restaurant Tour</li>
                                            <li>Gibbes Museum Of Art</li>
                                            <li>Haunted Jail Tour</li>
                                            <li>Fort Sumter Tours</li>
                                            <li>South Carolina Aquarium</li>
                                            <li>Patriots Point Naval And Maritime Museum</li>
                                            <li>Mount Pleasant and Holly Beach Piers</li>
                                            <li>Whirlin’ Waters Adventure Waterpark</li>
                                            <li>Isle Of Palms County Park </li>
                                            <li>Splash Island Waterpark</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
            <div className="single-property-testimonials">
                <div className="single-property-testimonials-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-title-inner">
                                    <h4>Testimonials</h4>
                                    <p>What People Say About Us</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-testimonials-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-content-inner">
                                    <div className="single-property-testimonials-slider">
                                        <Slider className="single-property-testimonials-slider" {...settings} >
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Wonderful place to spend a vacation. I was there for business and can’t wait to bring my spouse back to this place for a peaceful getaway.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Lyssa</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>We had an incredible stay here. Beautiful condo, extremely clean and a gorgeous ocean view. To top it off the owners were very kind and gave great suggestions for local restaurants, etc. Overall fantastic value. We will certainly be back!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Noelle</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Great corner unit with wonderful views of the ocean! Very comfortable and clean. Very easy to access with parking.There are great restaurants a short car ride away. We would definitely enjoy a return visit during better weather.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Kitty</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-price-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className="mb-3">Rates</h3>
                                <p className="left">Weekly rates include housekeeping, linens and towels. When booking less when a week there is housekeeping fee of $125 charged (plus $18 tax). Three to seven night stays booked from September thru May. One couple only Summer rentals run Saturday to Saturday. There is an Accommodation Tax (State, City and County) of 14%. Thanksgiving, Christmas and New Years have surcharge of $200.  4 pm check in, 10 am check out. Once you make the decision to rent from us you will fill out the form below. I, in turn, will email you a Contract. Sign it and mail back with an Advance Payment. Balance owed will be due 30 days before your rental. Full refund (less $75) in the event of cancellation assuming re-renting is possible.</p>
                                <p>There is housekeeping fee of $140 charge (includes housekeeping, linens and towels).  Three-night minimum stay. Not to extend over Saturday night without permission. Showing the nightly rates below except summer which rents by the week, Saturday to Saturday.  There is an Accommodation Tax (State, City and County) of 14%. 4 pm check in and 10 am check out.</p>
                                <p>January 1 - March 4...$235</p>
                                <p>March 5 - April 29...$285</p>
                                <p>April 30 - May 27...$325</p>
                                <p>May 28 - Sept 2...$2625 per week</p>
                                <p>Sept 3 - September 30...$300</p>
                                <p>October 1 - November 4...$250</p>
                                <p>November 5 - December 23...$210</p>
                                <p>Last week of December...$300</p>
                                <h3 className="mt-5">Making a Reservation</h3>
                                <h6 className="mt-2">It is imperative that the information we give you is YOUR full rental cost and availability is tailored to YOUR particular wishes and up to date with our present schedule of bookings.</h6>
                                <h6 className="mt-2">Email me at: <a href="mailto:coleresortrentals@gmail.com">coleresortrentals@gmail.com</a>, writing down the rental location you want to go to and the dates you would like. We will be back in touch ASAP.</h6>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PortOCallWildDunes;