import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../common/footer";
import Header from "../common/header";

import contact_banner from '../assets/image/contact-banner.jpg';
import instagram from '../assets/image/instagram.png';
import twitter from '../assets/image/twitter.png';
import facebook from '../assets/image/facebook.png';
import youtube from '../assets/image/youtube.png';
// import { transporter } from '../utility/utility';
import { Mailer } from 'nodemailer-react'
import axios from 'axios';

function Contact() {

    const [firstname, setfirstname] = useState('')
    const [lastname, setlastname] = useState('')
    const [phone, setphone] = useState('')
    const [email, setemail] = useState('')
    const [gorc, setgorc] = useState('')
    const [longtext, setlongtext] = useState('')

    const [errorfirstname, seterrorfirstname] = useState(false)
    const [errorlastname, seterrorlastname] = useState(false)
    const [errorphone, seterrorphone] = useState(false)
    const [erroremail, seterroremail] = useState(false)
    const [errorgorc, seterrorgorc] = useState(false)
    const [errorlongtext, seterrorlongtext] = useState(false)

    const submit = () => {

        firstname ? seterrorfirstname(false) : seterrorfirstname(true)
        lastname ? seterrorlastname(false) : seterrorlastname(true)
        phone ? seterrorphone(false) : seterrorphone(true)
        email ? seterroremail(false) : seterroremail(true)
        gorc ? seterrorgorc(false) : seterrorgorc(true)
        longtext ? seterrorlongtext(false) : seterrorlongtext(true)

        if (firstname && lastname && phone && email && gorc && longtext) {
            // console.log("email is sending...");


            // axios.post('http://localhost:3002/sendemail', {
            axios.post('http://cipherbrainstest.com:3002/sendemail', {
                toemail: "demo9196@gmail.com",
                subject: "SC Contact form",
                body_html: getEmailHtml()
            })
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status) {
                        
                       console.log(response.data.message);

                    } else {
                        
                    }
                }).catch(function (error) {
                    console.log(error)
                })
        }

    }


    const getEmailHtml = () => {

        return "<style type='text/css'>" +
            "   table{width:100%;border-spacing:0;border:none;border-collapse: collapse;}.right{text-align: right;}.left{text-align: left;}." +
            "</style>" +
            "<table>" +
            "    <tr>" +
            "        <td width='210px' style='font-family: Verdana;'>First name:</td>" +
            "        <td width='390px' style='font-family: Verdana;'>" + firstname + "</td>" +
            "    </tr>" +
            "    <tr>" +
            "        <td width='210px' style='font-family: Verdana;'>Last name:</td>" +
            "        <td width='390px' style='font-family: Verdana;'>" + lastname + "</td>" +
            "     </tr>" +
            "     <tr>" +
            "        <td width='210px' style='font-family: Verdana;'>Email:</td>" +
            "        <td width='390px' style='font-family: Verdana;'>" + email + "</td>" +
            "     </tr>" +
            "     <tr>" +
            "        <td width='210px' style='font-family: Verdana;'>Phone number:</td>" +
            "        <td width='390px' style='font-family: Verdana;'>" + phone + "</td>" +
            "     </tr>" +
            "     <tr>" +
            "        <td width='210px' style='font-family: Verdana;'>Group Or Company:</td>" +
            "        <td width='390px' style='font-family: Verdana;'>" + gorc + "</td>" +
            "     </tr>" +
            "     <tr>" +
            "        <td width='210px' style='font-family: Verdana;'>How We Can Help?:</td>" +
            "        <td width='390px' style='font-family: Verdana;'>" + longtext + "</td>" +
            "     </tr>" +
            "</table>"
    }


    return (
        <div className="main_div">
            <Header />
            <div className="main">
                <div className="page-header-with-back-image-title-subtitle back-bottom-center even-padding" style={{ backgroundImage: `url("${contact_banner}")` }}>
                    <Container>
                        <Row>
                            <Col xl={3} lg={2} md={12} sm={12}></Col>
                            <Col xl={6} lg={8} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                                <div className="page-header-with-back-image-title-subtitle-inner text-center">
                                    <h1>Contact</h1>
                                </div>
                            </Col>
                            <Col xl={3} lg={2} md={12} sm={12}></Col>
                        </Row>
                    </Container>
                </div>
                <div className="contact-form-contact-info">
                    <Container>
                        <Row>
                            <Col xl={1} lg={12} md={12} sm={12} className="contact-form-contact-info-column_1"></Col>
                            <Col xl={6} lg={7} md={12} sm={12} className="contact-form-contact-info-column_2 pe-0">
                                <div className="contact-form">
                                    <div className="contact-form-title">
                                        <h4>Send message</h4>
                                    </div>
                                    <div className="contact-form-inner">
                                        <form>
                                            <div className="contact-form-field-row">
                                                <Row>
                                                    <Col xl={6} lg={6} md={6} sm={12}>
                                                        <div className="contact-form-field first_name">
                                                            <input type="text" name="first_name" placeholder="First Name" onChange={e => setfirstname(e.target.value)} onFocus={() => seterrorfirstname(false)} />
                                                            {errorfirstname && <p className="error_msg">Enter Your First Name</p>}
                                                        </div>
                                                    </Col>
                                                    <Col xl={6} lg={6} md={6} sm={12}>
                                                        <div className="contact-form-field last_name">
                                                            <input type="text" name="last_name" placeholder="Last Name" onChange={e => setlastname(e.target.value)} onFocus={() => seterrorlastname(false)} />
                                                            {errorlastname && <p className="error_msg">Enter Your Last Name</p>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="contact-form-field-row">
                                                <Row>
                                                    <Col xl={6} lg={6} md={6} sm={12}>
                                                        <div className="contact-form-field phone">
                                                            <input type="tel" name="phone" placeholder="Phone" onChange={e => setphone(e.target.value)} onFocus={() => seterrorphone(false)} />
                                                            {errorphone && <p className="error_msg">Enter Your Phone</p>}
                                                        </div>
                                                    </Col>
                                                    <Col xl={6} lg={6} md={6} sm={12}>
                                                        <div className="contact-form-field email">
                                                            <input type="email" name="email" placeholder="Email" onChange={e => setemail(e.target.value)} onFocus={() => seterroremail(false)} />
                                                            {erroremail && <p className="error_msg">Enter Your Email</p>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="contact-form-field-row">
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={12}>
                                                        <div className="contact-form-field group_company">
                                                            <input type="text" name="group_company" placeholder="Group Or Company" onChange={e => setgorc(e.target.value)} onFocus={() => seterrorgorc(false)} />
                                                            {errorgorc && <p className="error_msg">Enter Group Or Company</p>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="contact-form-field-row">
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={12}>
                                                        <div className="contact-form-field how_can_we_help">
                                                            <textarea name="how_can_we_help" placeholder="How We Can Help?" onChange={e => setlongtext(e.target.value)} onFocus={() => seterrorlongtext(false)}></textarea>
                                                            {errorlongtext && <p className="error_msg">Enter How We Can Help?</p>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="contact-form-field-row">
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={12}>
                                                        <div className="contact-form-field submit_input">
                                                            <input type="button" className="btn" value="Submit" onClick={() => submit()} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={4} lg={5} md={12} sm={12} className="contact-form-contact-info-column_3 ps-0">
                                <div className="contact-info">
                                    <div className="contact-info-title"><h4>Contact info</h4></div>
                                    <div className="contact-info-contact-details">
                                        <ul>
                                            <li className="tel"><a href="tel:+1(704)975-4744">(704) 975-4744</a></li>
                                            <li className="email"><a href="mailto:coleresortrentals@gmail.com">coleresortrentals@gmail.com</a></li>
                                        </ul>
                                    </div>
                                    <div className="contact-info-social">
                                        <ul>
                                            <li><a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram" /></a></li>
                                            <li><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter" /></a></li>
                                            <li><a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" /></a></li>
                                            <li><a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer"><img src={youtube} alt="youtube" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={1} lg={12} md={12} sm={12} className="contact-form-contact-info-column_4"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;