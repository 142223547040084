import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../common/footer";
import Header from "../common/header";
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'

import WildDunesGeneric_1 from '../assets/image/WildDunesCommunity/WildDunesGeneric_1.jpg';
import WildDunesGeneric_2 from '../assets/image/WildDunesCommunity/WildDunesGeneric_2.jpg';
import WildDunesGeneric_3 from '../assets/image/WildDunesCommunity/WildDunesGeneric_3.jpg';
import WildDunesGeneric_4 from '../assets/image/WildDunesCommunity/WildDunesGeneric_4.jpg';
import WildDunesGeneric_5 from '../assets/image/WildDunesCommunity/WildDunesGeneric_5.jpg';
import WildDunesGeneric_6 from '../assets/image/WildDunesCommunity/WildDunesGeneric_6.jpg';
import WildDunesGeneric_7 from '../assets/image/WildDunesCommunity/WildDunesGeneric_7.jpg';
import WildDunesGeneric_8 from '../assets/image/WildDunesCommunity/WildDunesGeneric_8.jpg';
import WildDunesGeneric_9 from '../assets/image/WildDunesCommunity/WildDunesGeneric_9.jpg';
import WildDunesGeneric_10 from '../assets/image/WildDunesCommunity/WildDunesGeneric_10.jpg';
import WildDunesGeneric_11 from '../assets/image/WildDunesCommunity/WildDunesGeneric_11.jpg';
import WildDunesGeneric_12 from '../assets/image/WildDunesCommunity/WildDunesGeneric_12.jpg';
import WildDunesGeneric_13 from '../assets/image/WildDunesCommunity/WildDunesGeneric_13.jpg';
import WildDunesGeneric_14 from '../assets/image/WildDunesCommunity/WildDunesGeneric_14.jpg';
import WildDunesGeneric_15 from '../assets/image/WildDunesCommunity/WildDunesGeneric_15.jpg';
import WildDunesGeneric_16 from '../assets/image/WildDunesCommunity/WildDunesGeneric_16.jpg';
import WildDunesGeneric_17 from '../assets/image/WildDunesCommunity/WildDunesGeneric_17.jpg';
import WildDunesGeneric_18 from '../assets/image/WildDunesCommunity/WildDunesGeneric_18.jpg';
import WildDunesGeneric_19 from '../assets/image/WildDunesCommunity/WildDunesGeneric_19.jpg';
import WildDunesGeneric_20 from '../assets/image/WildDunesCommunity/WildDunesGeneric_20.jpg';
import WildDunesGeneric_21 from '../assets/image/WildDunesCommunity/WildDunesGeneric_21.jpg';
import WildDunesGeneric_22 from '../assets/image/WildDunesCommunity/WildDunesGeneric_22.jpg';
import WildDunesGeneric_23 from '../assets/image/WildDunesCommunity/WildDunesGeneric_23.jpg';
import WildDunesGeneric_24 from '../assets/image/WildDunesCommunity/WildDunesGeneric_24.jpg';
import WildDunesGeneric_25 from '../assets/image/WildDunesCommunity/WildDunesGeneric_25.jpg';
import WildDunesGeneric_26 from '../assets/image/WildDunesCommunity/WildDunesGeneric_26.jpg';
import WildDunesGeneric_27 from '../assets/image/WildDunesCommunity/WildDunesGeneric_27.jpg';
import WildDunesGeneric_28 from '../assets/image/WildDunesCommunity/WildDunesGeneric_28.jpg';
import WildDunesGeneric_29 from '../assets/image/WildDunesCommunity/WildDunesGeneric_29.jpg';
import WildDunesGeneric_30 from '../assets/image/WildDunesCommunity/WildDunesGeneric_30.jpg';
import WildDunesGeneric_31 from '../assets/image/WildDunesCommunity/WildDunesGeneric_31.jpg';
import WildDunesGeneric_32 from '../assets/image/WildDunesCommunity/WildDunesGeneric_32.jpg';
import WildDunesGeneric_33 from '../assets/image/WildDunesCommunity/WildDunesGeneric_33.jpg';
import WildDunesGeneric_34 from '../assets/image/WildDunesCommunity/WildDunesGeneric_34.jpg';
import WildDunesGeneric_35 from '../assets/image/WildDunesCommunity/WildDunesGeneric_35.jpg';
import WildDunesGeneric_36 from '../assets/image/WildDunesCommunity/WildDunesGeneric_36.jpg';
import WildDunesGeneric_37 from '../assets/image/WildDunesCommunity/WildDunesGeneric_37.jpg';
import WildDunesGeneric_38 from '../assets/image/WildDunesCommunity/WildDunesGeneric_38.jpg';
import WildDunesGeneric_39 from '../assets/image/WildDunesCommunity/WildDunesGeneric_39.jpg';
import WildDunesGeneric_40 from '../assets/image/WildDunesCommunity/WildDunesGeneric_40.jpg';
import WildDunesGeneric_41 from '../assets/image/WildDunesCommunity/WildDunesGeneric_41.jpg';
import WildDunesGeneric_42 from '../assets/image/WildDunesCommunity/WildDunesGeneric_42.jpg';
import WildDunesGeneric_43 from '../assets/image/WildDunesCommunity/WildDunesGeneric_43.jpg';
import WildDunesGeneric_44 from '../assets/image/WildDunesCommunity/WildDunesGeneric_44.jpg';
import WildDunesGeneric_45 from '../assets/image/WildDunesCommunity/WildDunesGeneric_45.jpg';
import WildDunesGeneric_46 from '../assets/image/WildDunesCommunity/WildDunesGeneric_46.jpg';
import WildDunesGeneric_47 from '../assets/image/WildDunesCommunity/WildDunesGeneric_47.jpg';
import WildDunesGeneric_48 from '../assets/image/WildDunesCommunity/WildDunesGeneric_48.jpg';
import WildDunesGeneric_49 from '../assets/image/WildDunesCommunity/WildDunesGeneric_49.jpg';
import WildDunesGeneric_50 from '../assets/image/WildDunesCommunity/WildDunesGeneric_50.jpg';
import WildDunesGeneric_51 from '../assets/image/WildDunesCommunity/WildDunesGeneric_51.jpg';
import WildDunesGeneric_52 from '../assets/image/WildDunesCommunity/WildDunesGeneric_52.jpg';
import WildDunesGeneric_53 from '../assets/image/WildDunesCommunity/WildDunesGeneric_53.jpg';
import WildDunesGeneric_54 from '../assets/image/WildDunesCommunity/WildDunesGeneric_54.jpg';
import WildDunesGeneric_55 from '../assets/image/WildDunesCommunity/WildDunesGeneric_55.jpg';
import WildDunesGeneric_56 from '../assets/image/WildDunesCommunity/WildDunesGeneric_56.jpg';
import WildDunesGeneric_57 from '../assets/image/WildDunesCommunity/WildDunesGeneric_57.jpg';
import WildDunesGeneric_58 from '../assets/image/WildDunesCommunity/WildDunesGeneric_58.jpg';
import WildDunesGeneric_59 from '../assets/image/WildDunesCommunity/WildDunesGeneric_59.jpg';
import WildDunesGeneric_60 from '../assets/image/WildDunesCommunity/WildDunesGeneric_60.jpg';
import WildDunesGeneric_61 from '../assets/image/WildDunesCommunity/WildDunesGeneric_61.jpg';
import WildDunesGeneric_62 from '../assets/image/WildDunesCommunity/WildDunesGeneric_62.jpg';
import WildDunesGeneric_63 from '../assets/image/WildDunesCommunity/WildDunesGeneric_63.jpg';
import WildDunesGeneric_64 from '../assets/image/WildDunesCommunity/WildDunesGeneric_64.jpg';
import WildDunesGeneric_65 from '../assets/image/WildDunesCommunity/WildDunesGeneric_65.jpg';
import WildDunesGeneric_66 from '../assets/image/WildDunesCommunity/WildDunesGeneric_66.jpg';
import WildDunesGeneric_67 from '../assets/image/WildDunesCommunity/WildDunesGeneric_67.jpg';
import WildDunesGeneric_68 from '../assets/image/WildDunesCommunity/WildDunesGeneric_68.jpg';
import WildDunesGeneric_69 from '../assets/image/WildDunesCommunity/WildDunesGeneric_69.jpg';
import WildDunesGeneric_70 from '../assets/image/WildDunesCommunity/WildDunesGeneric_70.jpg';
import WildDunesGeneric_71 from '../assets/image/WildDunesCommunity/WildDunesGeneric_71.jpg';
import WildDunesGeneric_72 from '../assets/image/WildDunesCommunity/WildDunesGeneric_72.jpg';
import WildDunesGeneric_73 from '../assets/image/WildDunesCommunity/WildDunesGeneric_73.jpg';
import WildDunesGeneric_74 from '../assets/image/WildDunesCommunity/WildDunesGeneric_74.jpg';
import WildDunesGeneric_75 from '../assets/image/WildDunesCommunity/WildDunesGeneric_75.jpg';
import WildDunesGeneric_76 from '../assets/image/WildDunesCommunity/WildDunesGeneric_76.jpg';
import WildDunesGeneric_77 from '../assets/image/WildDunesCommunity/WildDunesGeneric_77.jpg';
import WildDunesGeneric_78 from '../assets/image/WildDunesCommunity/WildDunesGeneric_78.jpg';
import WildDunesGeneric_79 from '../assets/image/WildDunesCommunity/WildDunesGeneric_79.jpg';
import WildDunesGeneric_80 from '../assets/image/WildDunesCommunity/WildDunesGeneric_80.jpg';
import WildDunesGeneric_81 from '../assets/image/WildDunesCommunity/WildDunesGeneric_81.jpg';
import WildDunesGeneric_82 from '../assets/image/WildDunesCommunity/WildDunesGeneric_82.jpg';
import WildDunesGeneric_83 from '../assets/image/WildDunesCommunity/WildDunesGeneric_83.jpg';
import WildDunesGeneric_84 from '../assets/image/WildDunesCommunity/WildDunesGeneric_84.jpg';
import WildDunesGeneric_85 from '../assets/image/WildDunesCommunity/WildDunesGeneric_85.jpg';
import WildDunesGeneric_86 from '../assets/image/WildDunesCommunity/WildDunesGeneric_86.jpg';
import WildDunesGeneric_87 from '../assets/image/WildDunesCommunity/WildDunesGeneric_87.jpg';
import WildDunesGeneric_88 from '../assets/image/WildDunesCommunity/WildDunesGeneric_88.jpg';
import WildDunesGeneric_89 from '../assets/image/WildDunesCommunity/WildDunesGeneric_89.jpg';
import WildDunesGeneric_90 from '../assets/image/WildDunesCommunity/WildDunesGeneric_90.jpg';
import WildDunesGeneric_91 from '../assets/image/WildDunesCommunity/WildDunesGeneric_91.jpg';
import WildDunesGeneric_92 from '../assets/image/WildDunesCommunity/WildDunesGeneric_92.jpg';
import WildDunesGeneric_93 from '../assets/image/WildDunesCommunity/WildDunesGeneric_93.jpg';
import WildDunesGeneric_94 from '../assets/image/WildDunesCommunity/WildDunesGeneric_94.jpg';
import WildDunesGeneric_95 from '../assets/image/WildDunesCommunity/WildDunesGeneric_95.jpg';
import WildDunesGeneric_96 from '../assets/image/WildDunesCommunity/WildDunesGeneric_96.jpg';
import WildDunesGeneric_97 from '../assets/image/WildDunesCommunity/WildDunesGeneric_97.jpg';
import WildDunesGeneric_98 from '../assets/image/WildDunesCommunity/WildDunesGeneric_98.jpg';
import WildDunesGeneric_99 from '../assets/image/WildDunesCommunity/WildDunesGeneric_99.jpg';
import WildDunesGeneric_100 from '../assets/image/WildDunesCommunity/WildDunesGeneric_100.jpg';
import WildDunesGeneric_101 from '../assets/image/WildDunesCommunity/WildDunesGeneric_101.jpg';
import WildDunesGeneric_102 from '../assets/image/WildDunesCommunity/WildDunesGeneric_102.jpg';

function WildDunesCommunity() {

    return (
        <div className="main_div">
            <Header />
            <div className="page-header-with-back-image-title-subtitle even-padding" style={{ backgroundImage: `url("${WildDunesGeneric_101}")` }}>
                <Container>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <div className="page-header-with-back-image-title-subtitle-inner text-center">
                                <h1>Wild Dunes Community</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="wilddunesgallery">
                <Container>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_1} image={WildDunesGeneric_1} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_2} image={WildDunesGeneric_2} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_3} image={WildDunesGeneric_3} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_4} image={WildDunesGeneric_4} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_5} image={WildDunesGeneric_5} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_6} image={WildDunesGeneric_6} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_7} image={WildDunesGeneric_7} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_8} image={WildDunesGeneric_8} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_9} image={WildDunesGeneric_9} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_10} image={WildDunesGeneric_10} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_11} image={WildDunesGeneric_11} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_12} image={WildDunesGeneric_12} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_13} image={WildDunesGeneric_13} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_14} image={WildDunesGeneric_14} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_15} image={WildDunesGeneric_15} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_16} image={WildDunesGeneric_16} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_17} image={WildDunesGeneric_17} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_18} image={WildDunesGeneric_18} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_19} image={WildDunesGeneric_19} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_20} image={WildDunesGeneric_20} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_21} image={WildDunesGeneric_21} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_22} image={WildDunesGeneric_22} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_23} image={WildDunesGeneric_23} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_24} image={WildDunesGeneric_24} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_25} image={WildDunesGeneric_25} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_26} image={WildDunesGeneric_26} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_27} image={WildDunesGeneric_27} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_28} image={WildDunesGeneric_28} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_29} image={WildDunesGeneric_29} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_30} image={WildDunesGeneric_30} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_31} image={WildDunesGeneric_31} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_32} image={WildDunesGeneric_32} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_33} image={WildDunesGeneric_33} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_34} image={WildDunesGeneric_34} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_35} image={WildDunesGeneric_35} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_36} image={WildDunesGeneric_36} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_37} image={WildDunesGeneric_37} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_38} image={WildDunesGeneric_38} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_39} image={WildDunesGeneric_39} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_40} image={WildDunesGeneric_40} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_41} image={WildDunesGeneric_41} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_42} image={WildDunesGeneric_42} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_43} image={WildDunesGeneric_43} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_44} image={WildDunesGeneric_44} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_45} image={WildDunesGeneric_45} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_46} image={WildDunesGeneric_46} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_47} image={WildDunesGeneric_47} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_48} image={WildDunesGeneric_48} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_49} image={WildDunesGeneric_49} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_50} image={WildDunesGeneric_50} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_51} image={WildDunesGeneric_51} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_52} image={WildDunesGeneric_52} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_53} image={WildDunesGeneric_53} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_54} image={WildDunesGeneric_54} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_55} image={WildDunesGeneric_55} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_56} image={WildDunesGeneric_56} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_57} image={WildDunesGeneric_57} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_58} image={WildDunesGeneric_58} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_59} image={WildDunesGeneric_59} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_60} image={WildDunesGeneric_60} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_61} image={WildDunesGeneric_61} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_62} image={WildDunesGeneric_62} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_63} image={WildDunesGeneric_63} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_64} image={WildDunesGeneric_64} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_65} image={WildDunesGeneric_65} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_66} image={WildDunesGeneric_66} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_67} image={WildDunesGeneric_67} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_68} image={WildDunesGeneric_68} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_69} image={WildDunesGeneric_69} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_70} image={WildDunesGeneric_70} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_71} image={WildDunesGeneric_71} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_72} image={WildDunesGeneric_72} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_73} image={WildDunesGeneric_73} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_74} image={WildDunesGeneric_74} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_75} image={WildDunesGeneric_75} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_76} image={WildDunesGeneric_76} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_77} image={WildDunesGeneric_77} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_78} image={WildDunesGeneric_78} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_79} image={WildDunesGeneric_79} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_80} image={WildDunesGeneric_80} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_81} image={WildDunesGeneric_81} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_82} image={WildDunesGeneric_82} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_83} image={WildDunesGeneric_83} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_84} image={WildDunesGeneric_84} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_85} image={WildDunesGeneric_85} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_86} image={WildDunesGeneric_86} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_87} image={WildDunesGeneric_87} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_88} image={WildDunesGeneric_88} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_89} image={WildDunesGeneric_89} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_90} image={WildDunesGeneric_90} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_91} image={WildDunesGeneric_91} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_92} image={WildDunesGeneric_92} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_93} image={WildDunesGeneric_93} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_94} image={WildDunesGeneric_94} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_95} image={WildDunesGeneric_95} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_96} image={WildDunesGeneric_96} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_97} image={WildDunesGeneric_97} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_98} image={WildDunesGeneric_98} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_99} image={WildDunesGeneric_99} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_100} image={WildDunesGeneric_100} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_101} image={WildDunesGeneric_101} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={WildDunesGeneric_102} image={WildDunesGeneric_102} /></div>
                </Container>
            </div>
            <div className="wilddunesvideo">
                <Container>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <iframe title="Wild Dunes Branded SC Beach Vacations" width="100%" height="686" src="https://www.youtube.com/embed/gccz38XaN_4?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    );
}

export default WildDunesCommunity;