import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../common/footer";
import Header from "../common/header";


import homepage_banner from '../assets/image/homepage_banner.jpg';
import ocen_club_1504 from '../assets/image/1504-ocen-club.jpg';
import ocen_club_1104 from '../assets/image/1104-ocen-club.jpg';
import seagrove from '../assets/image/1b-seagrove.jpg';
import summer_house_204  from '../assets/image/Summerhouse.jpg';
import port_o_call  from '../assets/image/portocall.jpg';
import the_ashworth_1705  from '../assets/image/The-Ashworth.jpg';
import wellington_403  from '../assets/image/Wellington.jpg';
import lake_lanier_home  from '../assets/image/Lake-Lanier-Western-Style-Log-Home.jpg';
import contemporary_home  from '../assets/image/Contemporary-Home.jpg';
import rustic_log_cabin  from '../assets/image/Rustic-Log-Cabin.jpg';
import easy_online_booking from '../assets/image/easy_online_booking.png';
import we_direct_you from '../assets/image/we_direct_you.png';
import give_us_jingle from '../assets/image/give_us_jingle.png';
import WildDunesGeneric from '../assets/image/WildDunesCommunity/WildDunesGeneric_39.jpg';
import NorthMyrtleArea from '../assets/image/NorthMyrtleArea/northmyrtlearea_7.jpg';
import SummerHouse204WildDunes from '../assets/image/204SummerHouseWildDunes/204SummerHouseWildDunes_1.jpg';
import ContemporaryHomeonBeautifulLakeHartwell from '../assets/image/ContemporaryHomeonBeautifulLakeHartwell/Contemporary_Home_on_Beautiful_Lake_Hartwell_2.jpg';




function Home() {

    var settings = {
        dots: true,
        //infinite: false,
        arrows: false,
        infinite: true,
        //speed: 5000,
        autoplaySpeed: 5000,
        autoplay: true,
        //autoplay: false,
        adaptiveHeight: false,
        slidesToShow: 4,
        pauseOnFocus: true,
        cssEase: 'linear',
        pauseOnHover: true,
        swipeToSlide: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="main_div">
            <Header />
            <div className="main-banner-section">
                <div className="main-banner-section-image"><img src={homepage_banner} alt="homepage_banner" /></div>
                <div className="main-banner-section-title-button">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={6} md={8}>
                                <div className="main-banner-section-title"><h1>Welcome to South Carolina Beach Vacations</h1></div>
                                <div className="main-banner-section-button"><Button className="btn">View Properties</Button></div>
                            </Col>
                            <Col lg={6} md={4}></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="main-second-section">
                <div className="main-second-section-title">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="main-second-section-title-inner"><h4>Explore more travel vacation rentals</h4></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="main-second-section-content">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="main-second-section-content-inner">
                                    <Slider className="main-second-section-content-slider" {...settings} >
                                        <div className="slide">
                                            <div className="main_second_section_content_slide_inner">
                                                <a href="\1504-ocean-club-wild-dunes">
                                                    <div className="main_second_section_content_slide_inner_image"><img src={ocen_club_1504} alt="1504 Ocean Club" /></div>
                                                    <div className="main_second_section_content_slide_inner_title_info">
                                                        <div className="main_second_section_content_slide_inner_title"><h5>1504 Ocean Club</h5></div>
                                                        <div className="main_second_section_content_slide_inner_info"><span className="bedrooms">3 Bedrooms</span> – <span className="capacity">Sleeps 6</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        {/* <div className="slide">
                                            <div className="main_second_section_content_slide_inner">
                                                <a href="\1104-ocean-club-wild-dunes">
                                                    <div className="main_second_section_content_slide_inner_image"><img src={ocen_club_1104} alt="ocen_club" /></div>
                                                    <div className="main_second_section_content_slide_inner_title_info">
                                                        <div className="main_second_section_content_slide_inner_title"><h5>1104 Ocean Club</h5></div>
                                                        <div className="main_second_section_content_slide_inner_info"><span className="bedrooms">3 Bedrooms</span> – <span className="capacity">Sleeps 6</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> */}
                                        {/*<div className="slide">
                                            <div className="main_second_section_content_slide_inner">
                                                <a href="\1b-seagrove-wild-dunes">
                                                    <div className="main_second_section_content_slide_inner_image"><img src={seagrove} alt="1B Seagrove" /></div>
                                                    <div className="main_second_section_content_slide_inner_title_info">
                                                        <div className="main_second_section_content_slide_inner_title"><h5>1B Seagrove</h5></div>
                                                        <div className="main_second_section_content_slide_inner_info"><span className="bedrooms">3 Bedrooms</span> – <span className="capacity">Sleeps 6</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> */}
                                        {/*<div className="slide">
                                            <div className="main_second_section_content_slide_inner">
                                                <a href="\204-summer-house-wild-dunes">
                                                    <div className="main_second_section_content_slide_inner_image"><img src={summer_house_204} alt="204 Summer House" /></div>
                                                    <div className="main_second_section_content_slide_inner_title_info">
                                                        <div className="main_second_section_content_slide_inner_title"><h5>204 Summer House</h5></div>
                                                        <div className="main_second_section_content_slide_inner_info"><span className="bedrooms">2 Bedrooms</span> – <span className="capacity">Sleeps 6</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>*/}
                                        {/*<div className="slide">
                                            <div className="main_second_section_content_slide_inner">
                                                <a href="\a-301-port-o-call-wild-dunes">
                                                    <div className="main_second_section_content_slide_inner_image"><img src={port_o_call} alt="A-301 Port O’ Call" /></div>
                                                    <div className="main_second_section_content_slide_inner_title_info">
                                                        <div className="main_second_section_content_slide_inner_title"><h5>A-301 Port O’ Call</h5></div>
                                                        <div className="main_second_section_content_slide_inner_info"><span className="bedrooms">1 Bedrooms</span> – <span className="capacity">Sleeps 2</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>*/}
                                        <div className="slide">
                                            <div className="main_second_section_content_slide_inner">
                                                <a href="\1705-the-ashworth-oceanfront">
                                                    <div className="main_second_section_content_slide_inner_image"><img src={the_ashworth_1705} alt="1705 The Ashworth" /></div>
                                                    <div className="main_second_section_content_slide_inner_title_info">
                                                        <div className="main_second_section_content_slide_inner_title"><h5>1705 The Ashworth</h5></div>
                                                        <div className="main_second_section_content_slide_inner_info"><span className="bedrooms">4 Bedrooms</span> – <span className="capacity">Sleeps 10</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        {/*<div className="slide">
                                            <div className="main_second_section_content_slide_inner">
                                                <a href="\403-wellington-oceanfront">
                                                    <div className="main_second_section_content_slide_inner_image"><img src={wellington_403} alt="403 Wellington" /></div>
                                                    <div className="main_second_section_content_slide_inner_title_info">
                                                        <div className="main_second_section_content_slide_inner_title"><h5>403 Wellington</h5></div>
                                                        <div className="main_second_section_content_slide_inner_info"><span className="bedrooms">2 Bedrooms</span> – <span className="capacity">Sleeps 5</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>*/}
                                        {/* <div className="slide">
                                            <div className="main_second_section_content_slide_inner">
                                                <a href="\lake-lanier-western-style-log-home">
                                                    <div className="main_second_section_content_slide_inner_image"><img src={lake_lanier_home} alt="Lake Lanier Western Style Log Home" /></div>
                                                    <div className="main_second_section_content_slide_inner_title_info">
                                                        <div className="main_second_section_content_slide_inner_title"><h5>Lake Lanier Home</h5></div>
                                                        <div className="main_second_section_content_slide_inner_info"><span className="bedrooms">4 Bedrooms</span> – <span className="capacity">Sleeps 10</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> */}
                                        <div className="slide">
                                            <div className="main_second_section_content_slide_inner">
                                                <a href="\contemporary-home-on-beautiful-lake-hartwell">
                                                    <div className="main_second_section_content_slide_inner_image"><img src={contemporary_home} alt="Contemporary Home" /></div>
                                                    <div className="main_second_section_content_slide_inner_title_info">
                                                        <div className="main_second_section_content_slide_inner_title"><h5>Contemporary Home</h5></div>
                                                        <div className="main_second_section_content_slide_inner_info"><span className="bedrooms">4 Bedrooms</span> – <span className="capacity">Sleeps 12</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="slide">
                                            <div className="main_second_section_content_slide_inner">
                                                <a href="\1504-ocean-club-wild-dunes">
                                                    <div className="main_second_section_content_slide_inner_image"><img src={ocen_club_1504} alt="1504 Ocean Club" /></div>
                                                    <div className="main_second_section_content_slide_inner_title_info">
                                                        <div className="main_second_section_content_slide_inner_title"><h5>1504 Ocean Club</h5></div>
                                                        <div className="main_second_section_content_slide_inner_info"><span className="bedrooms">3 Bedrooms</span> – <span className="capacity">Sleeps 6</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="slide">
                                            <div className="main_second_section_content_slide_inner">
                                                <a href="\1705-the-ashworth-oceanfront">
                                                    <div className="main_second_section_content_slide_inner_image"><img src={the_ashworth_1705} alt="1705 The Ashworth" /></div>
                                                    <div className="main_second_section_content_slide_inner_title_info">
                                                        <div className="main_second_section_content_slide_inner_title"><h5>1705 The Ashworth</h5></div>
                                                        <div className="main_second_section_content_slide_inner_info"><span className="bedrooms">4 Bedrooms</span> – <span className="capacity">Sleeps 10</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="slide">
                                            <div className="main_second_section_content_slide_inner">
                                                <a href="\contemporary-home-on-beautiful-lake-hartwell">
                                                    <div className="main_second_section_content_slide_inner_image"><img src={contemporary_home} alt="Contemporary Home" /></div>
                                                    <div className="main_second_section_content_slide_inner_title_info">
                                                        <div className="main_second_section_content_slide_inner_title"><h5>Contemporary Home</h5></div>
                                                        <div className="main_second_section_content_slide_inner_info"><span className="bedrooms">4 Bedrooms</span> – <span className="capacity">Sleeps 12</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        {/* <div className="slide">
                                            <div className="main_second_section_content_slide_inner">
                                                <a href="\rustic-log-cabin-in-the-woods-of-north-georgia">
                                                    <div className="main_second_section_content_slide_inner_image"><img src={rustic_log_cabin} alt="Rustic Log Cabin" /></div>
                                                    <div className="main_second_section_content_slide_inner_title_info">
                                                        <div className="main_second_section_content_slide_inner_title"><h5>Rustic Log Cabin</h5></div>
                                                        <div className="main_second_section_content_slide_inner_info"><span className="bedrooms">4 Bedrooms</span> – <span className="capacity">Sleeps 10</span></div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> */}
                                    </Slider>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="main-third-section">
                <Container>
                    <Row>
                        <Col md={4}>
                            <div className="third-section-inner third-section-inner-one">
                                <div className="third-section-inner-image"><img src={easy_online_booking} alt="easy_online_booking" /></div>
                                <div className="third-section-inner-title"><h5>Easy Online Booking.</h5></div>
                                <div className="third-section-inner-text"><p>Pick Your Place. Click The Dates. Book. It’s That Easy.</p></div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="third-section-inner third-section-inner-one">
                                <div className="third-section-inner-image"><img src={we_direct_you} alt="we_direct_you" /></div>
                                <div className="third-section-inner-title"><h5>We’ll Direct You!</h5></div>
                                <div className="third-section-inner-text"><p>Directions To Your Place. Coded Entries. We’ll Even Lead You To The Things To Do.</p></div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="third-section-inner third-section-inner-one">
                                <div className="third-section-inner-image"><img src={give_us_jingle} alt="give_us_jingle" /></div>
                                <div className="third-section-inner-title"><h5>Just Give Us A Jingle.</h5></div>
                                <div className="third-section-inner-text"><p>We’re Here To Help. Just Ask Peter Or David!</p></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="main-fourth-section">
                <Container>
                    <Row className="align-items-center">
                        <Col xxl={6} xl={6} lg={6}>
                            <div className="main-fourth-section-inner-left">
                                <Row>
                                    <Col xl={6} md={6} className="main-fourth-section-inner-left-single-column left">
                                        <div className="main-fourth-section-inner-left-single">
                                            <div className="main-fourth-section-inner-left-single-image"><img src={SummerHouse204WildDunes} alt="charleston_properties" /></div>
                                            <div className="main-fourth-section-inner-left-single-title"><h6>Charleston Properties</h6></div>
                                        </div>
                                        <div className="main-fourth-section-inner-left-single">
                                            <div className="main-fourth-section-inner-left-single-image"><img src={WildDunesGeneric} alt="WildDunesGeneric" /></div>
                                            <div className="main-fourth-section-inner-left-single-title"><h6>Wild Dunes Properties</h6></div>
                                        </div>
                                    </Col>
                                    <Col xl={6} md={6} className="main-fourth-section-inner-left-single-column right">
                                        <div className="main-fourth-section-inner-left-single">
                                            <div className="main-fourth-section-inner-left-single-image"><img src={NorthMyrtleArea} alt="NorthMyrtleArea" /></div>
                                            <div className="main-fourth-section-inner-left-single-title"><h6>North Myrtle Properties</h6></div>
                                        </div>
                                        <div className="main-fourth-section-inner-left-single">
                                            <div className="main-fourth-section-inner-left-single-image"><img src={ContemporaryHomeonBeautifulLakeHartwell} alt="charleston_properties" /></div>
                                            <div className="main-fourth-section-inner-left-single-title"><h6>Other Properties</h6></div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xxl={1} xl={1} lg={1} className="d-none d-xl-block d-xxl-block"></Col>
                        <Col xxl={5} xl={5} lg={5}>
                            <div className="main-fourth-section-inner-right">
                                <h5>Destinations</h5>
                                <h4>Popular Destinations Outside USA.</h4>
                                <div className="horizontal-row"></div>
                                <div className="fourth-section-right-text"><p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin</p></div>
                                <div className="fourth-section-right-button"><a href="/about" className="btn">Discover</a></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="main-fifth-section">
                <Container>
                    <Row>
                        <Col xxl={2} xl={2} lg={2} className="d-none d-xl-block d-xxl-block d-lg-block"></Col>
                        <Col xxl={8} xl={8} lg={8}>
                            <div className="main-fifth-section-title">
                                <h4>Subscribe Our Blog</h4>
                            </div>
                            <div className="main-fifth-section-form">
                                <form className="subscribe_blog_form" method="post">
                                    <div className="subscribe_blog_form_inn">
                                        <div className="subscribe_blog_for_input">
                                            <input id="email_input_form" className="emailadd-input FormControl" type="text" placeholder="Enter your email address" value="" autocapitalize="off" autocomplete="off" />
                                        </div>
                                        <div className="subscribe_blog_for_btn">
                                            <input type="submit" value="Subscribe Now" />
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </Col>
                        <Col xxl={2} xl={2} lg={2} className="d-none d-xl-block d-xxl-block d-lg-block"></Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    );
}

export default Home;
