import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../common/footer";
import Header from "../common/header";

import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import OceanClub1104WildDunes_1 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_1.jpg';
import OceanClub1104WildDunes_2 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_2.jpg';
import OceanClub1104WildDunes_3 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_3.jpg';
import OceanClub1104WildDunes_4 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_4.jpg';
import OceanClub1104WildDunes_5 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_5.jpg';
import OceanClub1104WildDunes_6 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_6.jpg';
import OceanClub1104WildDunes_7 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_7.jpg';
import OceanClub1104WildDunes_8 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_8.jpg';
import OceanClub1104WildDunes_9 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_9.jpg';
import OceanClub1104WildDunes_10 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_10.jpg';
import OceanClub1104WildDunes_11 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_11.jpg';
import OceanClub1104WildDunes_12 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_12.jpg';
import OceanClub1104WildDunes_13 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_13.jpg';
import OceanClub1104WildDunes_14 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_14.jpg';
import OceanClub1104WildDunes_15 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_15.jpg';
import OceanClub1104WildDunes_16 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_16.jpg';
import OceanClub1104WildDunes_17 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_17.jpg';
import OceanClub1104WildDunes_18 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_18.jpg';
import OceanClub1104WildDunes_19 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_19.jpg';
import OceanClub1104WildDunes_20 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_20.jpg';
import OceanClub1104WildDunes_21 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_21.jpg';
import OceanClub1104WildDunes_22 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_22.jpg';
import OceanClub1104WildDunes_23 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_23.jpg';
import OceanClub1104WildDunes_24 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_24.jpg';
import OceanClub1104WildDunes_25 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_25.jpg';
import OceanClub1104WildDunes_26 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_26.jpg';
import OceanClub1104WildDunes_27 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_27.jpg';
import OceanClub1104WildDunes_28 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_28.jpg';
import OceanClub1104WildDunes_29 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_29.jpg';
import OceanClub1104WildDunes_30 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_30.jpg';
import OceanClub1104WildDunes_31 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_31.jpg';
import OceanClub1104WildDunes_32 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_32.jpg';
import OceanClub1104WildDunes_33 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_33.jpg';
import OceanClub1104WildDunes_34 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_34.jpg';
import OceanClub1104WildDunes_35 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_35.jpg';
import OceanClub1104WildDunes_36 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_36.jpg';
import OceanClub1104WildDunes_37 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_37.jpg';
import OceanClub1104WildDunes_38 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_38.jpg';
import OceanClub1104WildDunes_39 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_39.jpg';
import OceanClub1104WildDunes_40 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_40.jpg';
import OceanClub1104WildDunes_41 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_41.jpg';
import OceanClub1104WildDunes_42 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_42.jpg';
import OceanClub1104WildDunes_43 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_43.jpg';
import OceanClub1104WildDunes_44 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_44.jpg';
import OceanClub1104WildDunes_45 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_45.jpg';
import OceanClub1104WildDunes_46 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_46.jpg';
import OceanClub1104WildDunes_47 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_47.jpg';
import OceanClub1104WildDunes_48 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_48.jpg';
import OceanClub1104WildDunes_49 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_49.jpg';
import OceanClub1104WildDunes_50 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_50.jpg';
import OceanClub1104WildDunes_51 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_51.jpg';
import OceanClub1104WildDunes_52 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_52.jpg';
import OceanClub1104WildDunes_53 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_53.jpg';
import OceanClub1104WildDunes_54 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_54.jpg';
import OceanClub1104WildDunes_55 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_55.jpg';
import OceanClub1104WildDunes_56 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_56.jpg';
import OceanClub1104WildDunes_57 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_57.jpg';
import OceanClub1104WildDunes_58 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_58.jpg';
import OceanClub1104WildDunes_59 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_59.jpg';
import OceanClub1104WildDunes_60 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_60.jpg';
import OceanClub1104WildDunes_61 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_61.jpg';
import OceanClub1104WildDunes_62 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_62.jpg';
import OceanClub1104WildDunes_63 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_63.jpg';
import OceanClub1104WildDunes_64 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_64.jpg';
import OceanClub1104WildDunes_65 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_65.jpg';
import OceanClub1104WildDunes_66 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_66.jpg';
import OceanClub1104WildDunes_67 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_67.jpg';
import OceanClub1104WildDunes_68 from '../assets/image/1104OceanClubWildDunes/1104OceanClubWildDunes_68.jpg';

function OceanClub1104WildDunes() {


    var settings = {
        dots: true,
        //infinite: false,
        arrows: false,
        infinite: true,
        //speed: 5000,
        autoplaySpeed: 5000,
        //autoplay: true,
        autoplay: false,
        adaptiveHeight: false,
        slidesToShow: 1,
        pauseOnFocus: true,
        cssEase: 'ease',
        fade: true,
        pauseOnHover: true,
        swipeToSlide: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="main_div">
            <Header />
            <div className="page-header-with-back-image-title-subtitle" style={{ backgroundImage: `url("${OceanClub1104WildDunes_68}")` }} >
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <div className="page-header-with-back-image-title-subtitle-inner">
                                <h1>1104 Ocean Club – Wild Dunes</h1>
                                <h3>START FROM <span>$377</span> <sub>/ NIGHT</sub></h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-properties-gallery-outer">
                <Container>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_1} image={OceanClub1104WildDunes_1} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_2} image={OceanClub1104WildDunes_2} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_3} image={OceanClub1104WildDunes_3} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_4} image={OceanClub1104WildDunes_4} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_5} image={OceanClub1104WildDunes_5} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_6} image={OceanClub1104WildDunes_6} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_7} image={OceanClub1104WildDunes_7} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_8} image={OceanClub1104WildDunes_8} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_9} image={OceanClub1104WildDunes_9} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_10} image={OceanClub1104WildDunes_10} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_11} image={OceanClub1104WildDunes_11} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_12} image={OceanClub1104WildDunes_12} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_13} image={OceanClub1104WildDunes_13} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_14} image={OceanClub1104WildDunes_14} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_15} image={OceanClub1104WildDunes_15} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_16} image={OceanClub1104WildDunes_16} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_17} image={OceanClub1104WildDunes_17} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_18} image={OceanClub1104WildDunes_18} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_19} image={OceanClub1104WildDunes_19} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_20} image={OceanClub1104WildDunes_20} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_21} image={OceanClub1104WildDunes_21} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_22} image={OceanClub1104WildDunes_22} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_23} image={OceanClub1104WildDunes_23} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_24} image={OceanClub1104WildDunes_24} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_25} image={OceanClub1104WildDunes_25} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_26} image={OceanClub1104WildDunes_26} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_27} image={OceanClub1104WildDunes_27} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_28} image={OceanClub1104WildDunes_28} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_29} image={OceanClub1104WildDunes_29} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_30} image={OceanClub1104WildDunes_30} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_31} image={OceanClub1104WildDunes_31} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_32} image={OceanClub1104WildDunes_32} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_33} image={OceanClub1104WildDunes_33} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_34} image={OceanClub1104WildDunes_34} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_35} image={OceanClub1104WildDunes_35} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_36} image={OceanClub1104WildDunes_36} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_37} image={OceanClub1104WildDunes_37} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_38} image={OceanClub1104WildDunes_38} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_39} image={OceanClub1104WildDunes_39} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_40} image={OceanClub1104WildDunes_40} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_41} image={OceanClub1104WildDunes_41} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_42} image={OceanClub1104WildDunes_42} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_43} image={OceanClub1104WildDunes_43} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_44} image={OceanClub1104WildDunes_44} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_45} image={OceanClub1104WildDunes_45} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_46} image={OceanClub1104WildDunes_46} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_47} image={OceanClub1104WildDunes_47} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_48} image={OceanClub1104WildDunes_48} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_49} image={OceanClub1104WildDunes_49} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_50} image={OceanClub1104WildDunes_50} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_51} image={OceanClub1104WildDunes_51} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_52} image={OceanClub1104WildDunes_52} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_53} image={OceanClub1104WildDunes_53} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_54} image={OceanClub1104WildDunes_54} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_55} image={OceanClub1104WildDunes_55} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_56} image={OceanClub1104WildDunes_56} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_57} image={OceanClub1104WildDunes_57} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_58} image={OceanClub1104WildDunes_58} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_59} image={OceanClub1104WildDunes_59} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_60} image={OceanClub1104WildDunes_60} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_61} image={OceanClub1104WildDunes_61} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_62} image={OceanClub1104WildDunes_62} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_63} image={OceanClub1104WildDunes_63} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_64} image={OceanClub1104WildDunes_64} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_65} image={OceanClub1104WildDunes_65} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_66} image={OceanClub1104WildDunes_66} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_67} image={OceanClub1104WildDunes_67} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1104WildDunes_68} image={OceanClub1104WildDunes_68} /></div>
                </Container>
            </div>
            <div className="single-property-description">
                <div className="single-property-description-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-title-inner"><h4>Description Of Properties</h4></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-description-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-content-inner">
                                    <p>Prepare to be awesomely and pleasantly surprised the moment you enter the foyer of this exceptional villa. You’ll experience the amazing 270 º view of the ocean, beach, and golf course from our sixty feet of unobstructed windows in the living area and fabulous ocean views from every bedroom.</p>
                                    <p>We know that a discerning customer will appreciate and enjoy the rich details of this spacious 2,353 square foot paradise. Decorated in a classically British West Indies style, the beautiful hardwood floors, cherry wood moldings and cabinets, tiled foyer, halls, kitchen and laundry room and nine foot ceilings are a feast for the eyes. When you open all four double sliding doors in the living area, you will hear the tranquil cadence of the ocean waves and seagulls. The view is absolutely breathtaking. No other villa can claim a view like this one.</p>
                                    <p>Whether you are enjoying your lunch or sipping wine on the forty foot balcony overlooking the ocean waves, you’ll feel like you are sitting on the beach itself. The Ocean Club Villas have a huge swimming pool, a hot tub, a grill area and super deck for sunbathing. Our home amenities include among other things a variety of ‘start up’ supplies like shampoo, dish soap etc, and a well equipped kitchen. You’ll be surrounded not only by nature at its finest but inside we have signed numbered prints on display from the famous Charleston artist, Jim Maravel.</p>
                                    <p>When our villa was part of the Wild Dunes Rental Program, it was rated VIP status. They represent about 400 properties at Wild Dunes of which four percent (4%) have achieved VIP status. Our villa is booked a year in advance by many previous renters, who feel it is the best in Wild Dunes.</p>
                                    <p>You would expect us to sing our praises so please be sure to read the comments of past guests who tend to be a little more impartial than we are. Our calendar and rates are up to date. Please scroll down to consult them. We would especially appreciate you checking the calendar for availability before your inquiry. We have additional information to send you and/or if you have particular questions. Please don’t be shy about asking. We want to make sure we will meet your needs. We want this to be the best vacation you have ever had. Lots of pictures on the web site and even more important is the video. It helps tie together all the pictures. –</p>
                                    <p>Weeks only in the summer (Saturdays to Saturday). From September to May you can rent a minimum of three nights. Thanksgiving/Xmas/New Years has a Holiday Surcharge of $200, and you can only rent by the week. There is a charge of $175 for housekeeping/towels/ bed making. You can cancel at any time. We must be able to re-rent the period of time we have blocked for you to get a refund. There is a $75 cancellation fee. An Advance Payment is required when you send in your Contract. It varies from $500 to $1500 depending on the time of year you are renting, number of days you renting and how far in advance. There are no discounts for reservations made far in advance or last minute ones.</p>
                                    <p>HIGHLIGHTS IN REVIEW: 1) Check calendar for availability before submitting an inquiry. 2) Review the pictures and video. 3) Read comments by past guests. 4) Thank you so much for considering our place.</p>
                                    <p>I usually respond to an inquiry within 24 hours of receipt. If you haven’t heard from me within that frame period, check the calendar for availability. I usually don’t respond if we don’t have availability. Next try your spam. Sometimes I get caught there.</p>
                                    <p>FYI: As owners and managers we have an awesome responsibility….your vacation. It has got to be as perfect as we can make it. For many people it is the biggest event of the year. On our web site we hit the highlights. It is your responsibility to narrow down your search. We hope we will end up in the top two or three. We have more information to share with you that hopefully will increase the chances on making a good choice whether you book our place or not . Click on: “Ask Owner/Manager a Question” As soon as I hear from you I will do a tentative block of the dates. I will respond not only answering questions but sending detailed information.</p>
                                    <p>One of our local renters is a professional photographer.  If you have need of her services, please click on: www.jessicaclaire.net. Mention you saw her name on this web site to get a discount.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="single-property-video">
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <iframe title="1104 Ocean Club" width="100%" height="686" src="https://www.youtube.com/embed/g8ljVQGainE?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-property-amenities">
                <div className="single-property-amenities-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-amenities-title-inner mb-4"><h4>Highlights</h4></div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <div className="single-property-amenities-content-inner-content">
                            <Row>
                                <Col lg={4} md={4} sm={12}><ul><li>Property Type: Villa</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Area: 2,300 Square Feet</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-in: 4:00 PM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-out: 11:00 AM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Maximum Number Of Guests: 6</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Minimum Age Of Primary Renter: 25</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Children Welcome</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Parties/Events Not Allowed</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Pets Not Allowed</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Non Smoking Only</li></ul></Col>
                                <Col lg={8} md={8} sm={12}><ul><li>CANCELLATION – Full Refund Less $75 If We Can Re-Rent Your Booking Period</li></ul></Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={12} sm={12}><ul><li>Location Type: Beach, Resort, Beachfront, Golf Course Front</li></ul></Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="single-property-amenities-content">
                    <Container>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities Unit And Ocean Club</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>3&nbsp; Bedrooms, Sleeps 6</li>
                                            <li>3.5 Bathrooms</li>
                                            <li>1 King Sized Bed, 1 Queen Sized Bed</li>
                                            <li>2 Twin Or Single Beds, 2 Bunk Beds In Master Closet </li>
                                            <li>Jetted Tub In Master Bath</li>
                                            <li>Satellite, Cable TV, DVD Player, Wireless Internet</li>
                                            <li>VCR, Stereo, CD Player</li>
                                            <li>Books and Books For Adults</li>
                                            <li>Huge Deck/Balcony Space</li>
                                            <li>Heating and AC </li>
                                            <li>Hair Dryer, Basic Soaps, Toilet Paper </li>
                                            <li>Washer and Dryer, Ironing Board </li>
                                            <li>Ceiling Fans, Fireplace </li>
                                            <li>Oven, Stove, Dishwasher, Refrigerator, Microwave </li>
                                            <li>Blender, Toaster, Coffee Maker </li>
                                            <li>Dishes And Cooking Utensils </li>
                                            <li>Spacious Dining Room</li>
                                            <li>Towels And Linens Provided </li>
                                            <li>Elevator And Private Parking</li>
                                            <li>1 Bicycle (Complimentary)</li>
                                            <li>Outdoor Charcoal Grill</li>
                                            <li>Outdoor Pool At Ocean Club</li>
                                            <li>Shared Outdoor Hot Tub</li>
                                            <li>Walkway To The Beach</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities Wild Dunes</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>2 – 18 Hole Championship Golf Courses</li>
                                            <li>Miles of Bike/Running Paths</li>
                                            <li>Shopping And Restaurants In Community</li>
                                            <li>Gated And Private Access </li>
                                            <li>Basketball Court and Playgrounds </li>
                                            <li>Marina, Trailer Parking, Boat Launch</li>
                                        </ul>
                                    </div>
                                    <div className="single-property-amenities-content-inner-title"><h6>Things To Do Charleston</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>Many Amazing Shops And Restaurants</li>
                                            <li>Historical Carriage Tours</li>
                                            <li>Wine Tours</li>
                                            <li>Scenic Drives And Wildlife Viewing</li>
                                            <li>Horseback Riding</li>
                                            <li>Parasailing</li>
                                            <li>Deep Sea Fishing</li>
                                            <li>Speed Boat Adventures And Ghost Tours</li>
                                            <li>High Ropes Course Adventure</li>
                                            <li>Shem Creek Bar And Restaurant Tour</li>
                                            <li>Gibbes Museum Of Art</li>
                                            <li>Haunted Jail Tour</li>
                                            <li>Fort Sumter Tours</li>
                                            <li>South Carolina Aquarium</li>
                                            <li>Patriots Point Naval And Maritime Museum</li>
                                            <li>Mount Pleasant and Holly Beach Piers</li>
                                            <li>Whirlin’ Waters Adventure Waterpark</li>
                                            <li>Isle Of Palms County Park </li>
                                            <li>Splash Island Waterpark</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
            <div className="single-property-testimonials">
                <div className="single-property-testimonials-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-title-inner">
                                    <h4>Testimonials</h4>
                                    <p>What People Say About Us</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-testimonials-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-content-inner">
                                    <div className="single-property-testimonials-slider">
                                        <Slider className="single-property-testimonials-slider" {...settings} >
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>You can’t pick a better spot in Wild Dunes / IOP. The views are outstanding and no matter where you are in the condo, you see the ocean. You will not be disappointed. The ocean was a bit choppy while we were there and we wouldn’t let our kids swim, but the area pool is outstanding for everyone. Each bedroom has it’s own bathroom- huge bonus when traveling with a large family. The kitchen is well stocked with anything and everything you might need for a comfortable stay. We will definitely be returning to this fabulous beach condo!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Melissa</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>The condo has amazing 270 degrees ocean view. The pictures do not do the view justice. The 3.5 bathrooms is an unusual luxury as most 3 bedroom vacation homes do not have this convenience and privacy. Peter is awesome to work with his timely and helpful responses.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Kustav</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>This was our 2nd stay at Peter’s place because we absolutely love it there! The view is beyond amazing, the balconies offer intimate gathering places, and the condo is a perfect size. The bedrooms offer complete privacy and the common rooms provide great space for being together without stepping on each other. This trip was for our daughter’s wedding and we were able to host our niece and her family for brunch one morning and one evening we were able to have our only family left living in Mount Pleasant for supper. It truly feels like home every time we walk in. Peter supplies the condo with every thing you need (although if you cook you may want to bring an extra roll of paper towels – or I just may be a messy cook). After a few days in this condo you will be left relaxed, rested, and able to go back to your work life. Our family gives it a million stars!.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Laura</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>We had the pleasure of staying at 1104 Ocean Club in Wild Dunes 6/29/19 – 7/6/19.  The condo is spacious and beautifully appointed.  The beach/ocean frrom this first floor unit is breathtaking.  The pool area has just been redone and it’s beautiful.  Most importantly, the rental process was a pleasure.  Peter provided all the information from check-in to recommending rental companies, dinner, etc..  All of this made our family vacation truly special.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Gary B.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>I searched rentals for hours to find one that looked like it would be perfect for our family group. There were 6 of us. The condo was perfect, privacy but great to be all together,  elevator access to single floor living (we had rented a motorized scooter for my mom and it worked perfectly)! It was heavenly to have our coffee on the balcony, head to the beach for a walk,  lunch at the clubhouse across the street, an easy drive to the connector and Mt Pleasant! The fact that you can view the ocean from every room was wonderful; leaving the doors open to hear the sound of the ocean lull us to sleep!  I wouldn’t change a thing.   I would stay again in a heartbeat! Communication was easy, and we received our deposit back promptly.  Loved it!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Cheryl J., Fargo, ND</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>We read the reviews and we realize that some things can be embellished.  Not with this place. One of the things mentioned in the reviews and verified by the pictures is the incredible oceanfront views. We have stayed in a lot of oceanfront condos over the years but none prepared us for this one. The only place you could not see the ocean was in the half-bath and laundry room. We had heard that Ocean Club had experience lots of erosion in recent years. We also had heard that over the winter of 2017/2018, sixteen million dollars was spent to refurbish the beach. It looks incredible. Just a guess, but I would say at low tide the water’s edge is about 75 yards out. Take a good look at the pictures of the inside, especially the ones with wood. That is not cherry wood stain as we first suspected. It is real cherry wood. I had not noticed this in the pictures before we came but once there the evidence to detail was quite evident, things like massive crown molding throughout. This may come out a bit cheesy showing me somewhat of a romantic. So be it. Go back to the pictures on the web site and find the one of the pool at night with lights on inside the pool. Picture yourself lounging there after a busy day in Charleston, with someone special and drinking a glass of wine. It is a memory I hope will last until we can get back down there again.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>David K.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>I have been coming to  Isle of Palms for the past few years and this condo has  the best views! The beach is pretty much right under the living room balcony and the beach was huge compared to previous years, even at low tide. I like that you get the option of using the pool if you don’t feel like going to the beach and vice versa. The condo itself is very spacious and has 3.5 bathrooms which is awesome, especially when you are there for a girls weekend. We had breakfast and lunch on the balcony overlooking the ocean almost every day and it was so beautiful. The condo was very clean and I would definitely recommend it. Can’t beat the views.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Marissa F.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>My family and I have been going to wild dunes for many years.  We wanted a place on the beach and were lucky to find this one!  This condo is spacious and clean and has the most amazing view of the ocean from every bedroom and living area. Hearing the waves and being so close to the water brings us back every year.All the comforts of home are here.!!! Looking forward to returning in October.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Gail G.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>I just recently stayed at this condo for a week with friends. The weather was perfect for a morning walk or bike ride, for sitting on the beach, picking up shells , going to the pool or sitting on the balcony overlooking the ocean. The condo is tastefully decorated with three bedrooms and 3 1/2 baths. The layout is terrific for taking advantage of the ocean view. It is almost like being on a cruise ship! There is no doubt that this is the perfect condo for anyone that’s wants to relax and enjoy being by the ocean! I would highly recommend this unit.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Dale F.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>I have been vacationing in Wild Dunes over the past 10 years. Condo is wonderful with an ocean view from EVERY room. I look forward to sitting on the private deck off the bedroom with my morning coffee and being mesmerized by the ocean or sitting on one the front deck for lunchtime break. The view is spectacular and there are miles of beach to ride bikes or take long walks. The surrounding area has sidewalks and is impeccably manicured. This beachfront condo has a great open layout and is beautifully decorated. It has a fully equipped kitchen and a washer/dryer. It is always hard to leave such a beautiful place. I can’t wait to go again!!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Lois H.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-price-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className="mb-3">Rates</h3>
                                <p className="left">Three days minimum required from September through May. Only rents by the week in the summer, Saturday to Saturday. Thanksgiving (11/18-11/26), Christmas/New Year’s (12/15-1/2) have an extra $200 fee. Rent a month between Jan 1st and Mar 15 for $6447 (there is a 6-week limit). SC, City and County Accommodation Tax 14% (We Have Already Added This into Your Booking When You Book). Cleaning/sheets/towels and bed making add $275 to the cost. Cancellation Policy: There is a $75 fee. The rest of the rental money is returned assuming we can re-rent.</p>
                                <p>SUMMER PRICES ARE SHOWN AS WEEKLY RATES</p>
                                <p>Summer:  Base Cost $4,587.00 TAXES: $612.00 Cleaning: $275.00 TOTAL: $5,474.00</p>
                                <p>OFF-SEASON PRICES SHOWN ARE PER NIGHT</p>
                                <p>Spring: Nightly Rate: $444 Taxes: $62 Total: $506*</p>
                                <p>Fall: Nightly Rate: $417 Taxes $58 Total: $475*</p>
                                <p>Winter:  Nightly Rate $377 Taxes $53 Total: $430*</p>
                                <p>* Plus $275 for cleaning</p>

                                <h3 className="mt-5 mb-3">Availability Calendar</h3>
                                <iframe src="https://app.hosthub.com/rentals/236544" className='iframe'></iframe>

                                <h3 className="mt-5">Making a Reservation</h3>
                                <h6 className="mt-2">It is imperative that the information we give you is YOUR full rental cost and availability is tailored to YOUR particular wishes and up to date with our present schedule of bookings.</h6>
                                <h6 className="mt-2">Email me at: <a href="mailto:coleresortrentals@gmail.com">coleresortrentals@gmail.com</a>, writing down the rental location you want to go to and the dates you would like. We will be back in touch ASAP.</h6>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default OceanClub1104WildDunes;