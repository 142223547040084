import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from "../common/footer";
import Header from "../common/header";

import { faStar } from '@fortawesome/free-solid-svg-icons'

import our_properties_back from '../assets/image/our_properties_back.jpg';
import listing_204_summer_house from '../assets/image/listing-204-summer-house.jpg';
import listing_1504_ocean_club from '../assets/image/listing-1504-ocean-club.jpg';
import listing_1b_seagrove from '../assets/image/listing-1b-seagrove.jpg';

function OurProperties() {

    return (
        <div className="main_div">
            <Header />
            <div className="page-header-with-back-image-title-subtitle even-padding" style={{ backgroundImage: `url("${our_properties_back}")` }}>
                <Container>
                    <Row>
                        <Col xl={3} lg={2} md={12} sm={12}></Col>
                        <Col xl={6} lg={8} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <div className="page-header-with-back-image-title-subtitle-inner text-center">
                                <h1>Our Properties</h1>
                            </div>
                        </Col>
                        <Col xl={3} lg={2} md={12} sm={12}></Col>
                    </Row>
                </Container>
            </div>
            <div className="our-properties-listing">
                <div className="our-properties-listing-single our-properties-listing-single_1 odd" style={{ backgroundImage: `url("${listing_204_summer_house}")` }}>
                    <Container>
                        <Row>
                            <Col xl={5} lg={5} md={12} sm={12} className="column_one">
                                <div className="our-properties-listing-single-all-details">
                                    <div className="our-properties-listing-single-category-count">
                                        <label>Classic Properties</label>
                                        <p>01</p>
                                    </div>
                                    <div className="our-properties-listing-single-rating-reviews">
                                        <div className="single-property-related-single-rating-stars">
                                            <FontAwesomeIcon icon={faStar} className="checked" />
                                            <FontAwesomeIcon icon={faStar} className="checked" />
                                            <FontAwesomeIcon icon={faStar} />
                                            <FontAwesomeIcon icon={faStar} />
                                            <FontAwesomeIcon icon={faStar} />
                                        </div>
                                        <div className="single-property-related-single-rating-reviews">
                                            <span>5 Reviews</span>
                                        </div>
                                    </div>
                                    <div className="our-properties-listing-single-title">
                                        <h4><a href="/single-property">204 Summer House</a></h4>
                                    </div>
                                    <div className="our-properties-listing-single-pricing">
                                        <h6>Start from <i>$39</i> / Night</h6>
                                    </div>
                                    <div className="our-properties-listing-single-short-content">
                                        <p>Just down the road from your place is Barefoot Resort Landing and Main Street In North Myrtle. Whether you need a bite to eat, or your getting some shopping in, or you want to see Alabama at the Alabama Theatre.... GREAT Entertainment is at your fingertips.</p>
                                    </div>
                                    <div className="our-properties-listing-single-details">
                                        <ul>
                                            <li className="status"><label>Status :</label><span>Available</span></li>
                                            <li className="deposite"><label>Deposite :</label><span>Not required</span></li>
                                            <li className="beds"><label>Beds :</label><span>02</span></li>
                                            <li className="pessenger"><label>Passenger :</label><span>04</span></li>
                                        </ul>
                                    </div>
                                    <div className="our-properties-listing-single-link">
                                        <a href="/single-property">View detail</a>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={7} lg={7} md={12} sm={12} className="column_two">
                                <div className="our-properties-listing-single-image"><img src={listing_204_summer_house} alt="listing_204_summer_house" /></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div class="our-properties-listing-single our-properties-listing-single_2 even" style={{ backgroundImage: `url("${listing_1504_ocean_club}")` }}>
                    <Container>
                        <Row>
                            <Col xl={5} lg={5} md={12} sm={12} className="column_one">
                                <div class="our-properties-listing-single-all-details">
                                    <div class="our-properties-listing-single-category-count">
                                        <label>Deluxe Properties</label>
                                        <p>02</p>
                                    </div>
                                    <div class="our-properties-listing-single-rating-reviews">
                                        <div class="single-property-related-single-rating-stars">
                                            <FontAwesomeIcon icon={faStar} className="checked" />
                                            <FontAwesomeIcon icon={faStar} className="checked" />
                                            <FontAwesomeIcon icon={faStar} />
                                            <FontAwesomeIcon icon={faStar} />
                                            <FontAwesomeIcon icon={faStar} />
                                        </div>
                                        <div class="single-property-related-single-rating-reviews">
                                            <span>9 Reviews</span>
                                        </div>
                                    </div>
                                    <div class="our-properties-listing-single-title">
                                        <h4><a href="/single-property">1504 Ocean Club</a></h4>
                                    </div>
                                    <div class="our-properties-listing-single-pricing">
                                        <h6>Start from <i>$59</i> / Night</h6>
                                    </div>
                                    <div class="our-properties-listing-single-short-content">
                                        <p>Just down the road from your place is Barefoot Resort Landing and Main Street In North Myrtle. Whether you need a bite to eat, or your getting some shopping in, or you want to see Alabama at the Alabama Theatre.... GREAT Entertainment is at your fingertips.</p>
                                    </div>
                                    <div class="our-properties-listing-single-details">
                                        <ul>
                                            <li class="status"><label>Status :</label><span>03 Rooms Left !</span></li>
                                            <li class="deposite"><label>Deposite :</label><span>Required 30%</span></li>
                                            <li class="beds"><label>Beds :</label><span>02</span></li>
                                            <li class="pessenger"><label>Passenger :</label><span>04</span></li>
                                        </ul>
                                    </div>
                                    <div class="our-properties-listing-single-link">
                                        <a href="/single-property">View detail</a>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={7} lg={7} md={12} sm={12} className="column_two">
                                <div class="our-properties-listing-single-image"><img src={listing_1504_ocean_club} alt="listing_1504_ocean_club" /></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="our-properties-listing-single our-properties-listing-single_3 odd" style={{ backgroundImage: `url("${listing_1b_seagrove}")` }}>
                    <Container>
                        <Row>
                            <Col xl={5} lg={5} md={12} sm={12} className="column_one">
                                <div className="our-properties-listing-single-all-details">
                                    <div className="our-properties-listing-single-category-count">
                                        <label>Superior Properties</label>
                                        <p>03</p>
                                    </div>
                                    <div className="our-properties-listing-single-rating-reviews">
                                        <div className="single-property-related-single-rating-stars">
                                            <FontAwesomeIcon icon={faStar} className="checked" />
                                            <FontAwesomeIcon icon={faStar} className="checked" />
                                            <FontAwesomeIcon icon={faStar} className="checked" />
                                            <FontAwesomeIcon icon={faStar} className="checked" />
                                            <FontAwesomeIcon icon={faStar} />
                                        </div>
                                        <div className="single-property-related-single-rating-reviews">
                                            <span>5 Reviews</span>
                                        </div>
                                    </div>
                                    <div className="our-properties-listing-single-title">
                                        <h4><a href="/single-property">204 Summer House</a></h4>
                                    </div>
                                    <div className="our-properties-listing-single-pricing">
                                        <h6>Start from <i>$39</i> / Night</h6>
                                    </div>
                                    <div className="our-properties-listing-single-short-content">
                                        <p>Just down the road from your place is Barefoot Resort Landing and Main Street In North Myrtle. Whether you need a bite to eat, or your getting some shopping in, or you want to see Alabama at the Alabama Theatre.... GREAT Entertainment is at your fingertips.</p>
                                    </div>
                                    <div className="our-properties-listing-single-details">
                                        <ul>
                                            <li className="status"><label>Status :</label><span>Available</span></li>
                                            <li className="deposite"><label>Deposite :</label><span>Not required</span></li>
                                            <li className="beds"><label>Beds :</label><span>02</span></li>
                                            <li className="pessenger"><label>Passenger :</label><span>04</span></li>
                                        </ul>
                                    </div>
                                    <div className="our-properties-listing-single-link">
                                        <a href="/single-property">View detail</a>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={7} lg={7} md={12} sm={12} className="column_two">
                                <div className="our-properties-listing-single-image"><img src={listing_204_summer_house} alt="listing_204_summer_house" /></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default OurProperties;