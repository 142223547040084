import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../common/footer";
import Header from "../common/header";
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'

import NorthMyrtleArea_1 from '../assets/image/NorthMyrtleArea/northmyrtlearea_1.jpg';
import NorthMyrtleArea_2 from '../assets/image/NorthMyrtleArea/northmyrtlearea_2.jpg';
import NorthMyrtleArea_3 from '../assets/image/NorthMyrtleArea/northmyrtlearea_3.jpg';
import NorthMyrtleArea_4 from '../assets/image/NorthMyrtleArea/northmyrtlearea_4.jpg';
import NorthMyrtleArea_5 from '../assets/image/NorthMyrtleArea/northmyrtlearea_5.jpg';
import NorthMyrtleArea_6 from '../assets/image/NorthMyrtleArea/northmyrtlearea_6.jpg';
import NorthMyrtleArea_7 from '../assets/image/NorthMyrtleArea/northmyrtlearea_7.jpg';
import NorthMyrtleArea_8 from '../assets/image/NorthMyrtleArea/northmyrtlearea_8.jpg';
import NorthMyrtleArea_9 from '../assets/image/NorthMyrtleArea/northmyrtlearea_9.jpg';
import NorthMyrtleArea_10 from '../assets/image/NorthMyrtleArea/northmyrtlearea_10.jpg';
import NorthMyrtleArea_11 from '../assets/image/NorthMyrtleArea/northmyrtlearea_11.jpg';
import NorthMyrtleArea_12 from '../assets/image/NorthMyrtleArea/northmyrtlearea_12.jpg';
import NorthMyrtleArea_13 from '../assets/image/NorthMyrtleArea/northmyrtlearea_13.jpg';
import NorthMyrtleArea_14 from '../assets/image/NorthMyrtleArea/northmyrtlearea_14.jpg';
import NorthMyrtleArea_15 from '../assets/image/NorthMyrtleArea/northmyrtlearea_15.jpg';
import NorthMyrtleArea_16 from '../assets/image/NorthMyrtleArea/northmyrtlearea_16.jpg';
import NorthMyrtleArea_17 from '../assets/image/NorthMyrtleArea/northmyrtlearea_17.jpg';
import NorthMyrtleArea_18 from '../assets/image/NorthMyrtleArea/northmyrtlearea_18.jpg';
import NorthMyrtleArea_19 from '../assets/image/NorthMyrtleArea/northmyrtlearea_19.jpg';
import NorthMyrtleArea_20 from '../assets/image/NorthMyrtleArea/northmyrtlearea_20.jpg';
import NorthMyrtleArea_21 from '../assets/image/NorthMyrtleArea/northmyrtlearea_21.jpg';
import NorthMyrtleArea_22 from '../assets/image/NorthMyrtleArea/northmyrtlearea_22.jpg';
import NorthMyrtleArea_23 from '../assets/image/NorthMyrtleArea/northmyrtlearea_23.jpg';
import NorthMyrtleArea_24 from '../assets/image/NorthMyrtleArea/northmyrtlearea_24.jpg';
import NorthMyrtleArea_25 from '../assets/image/NorthMyrtleArea/northmyrtlearea_25.jpg';
import NorthMyrtleArea_26 from '../assets/image/NorthMyrtleArea/northmyrtlearea_26.jpg';
import NorthMyrtleArea_27 from '../assets/image/NorthMyrtleArea/northmyrtlearea_27.jpg';
import NorthMyrtleArea_28 from '../assets/image/NorthMyrtleArea/northmyrtlearea_28.jpg';
import NorthMyrtleArea_29 from '../assets/image/NorthMyrtleArea/northmyrtlearea_29.jpg';
import NorthMyrtleArea_30 from '../assets/image/NorthMyrtleArea/northmyrtlearea_30.jpg';
import NorthMyrtleArea_31 from '../assets/image/NorthMyrtleArea/northmyrtlearea_31.jpg';
import NorthMyrtleArea_32 from '../assets/image/NorthMyrtleArea/northmyrtlearea_32.jpg';
import NorthMyrtleArea_33 from '../assets/image/NorthMyrtleArea/northmyrtlearea_33.jpg';
import NorthMyrtleArea_34 from '../assets/image/NorthMyrtleArea/northmyrtlearea_34.jpg';
import NorthMyrtleArea_35 from '../assets/image/NorthMyrtleArea/northmyrtlearea_35.jpg';
import NorthMyrtleArea_36 from '../assets/image/NorthMyrtleArea/northmyrtlearea_36.jpg';
import NorthMyrtleArea_37 from '../assets/image/NorthMyrtleArea/northmyrtlearea_37.jpg';
import NorthMyrtleArea_38 from '../assets/image/NorthMyrtleArea/northmyrtlearea_38.jpg';
import NorthMyrtleArea_39 from '../assets/image/NorthMyrtleArea/northmyrtlearea_39.jpg';
import NorthMyrtleArea_40 from '../assets/image/NorthMyrtleArea/northmyrtlearea_40.jpg';
import NorthMyrtleArea_41 from '../assets/image/NorthMyrtleArea/northmyrtlearea_41.jpg';
import NorthMyrtleArea_42 from '../assets/image/NorthMyrtleArea/northmyrtlearea_42.jpg';
import NorthMyrtleArea_43 from '../assets/image/NorthMyrtleArea/northmyrtlearea_43.jpg';
import NorthMyrtleArea_44 from '../assets/image/NorthMyrtleArea/northmyrtlearea_44.jpg';
import NorthMyrtleArea_45 from '../assets/image/NorthMyrtleArea/northmyrtlearea_45.jpg';
import NorthMyrtleArea_46 from '../assets/image/NorthMyrtleArea/northmyrtlearea_46.jpg';
import NorthMyrtleArea_47 from '../assets/image/NorthMyrtleArea/northmyrtlearea_47.jpg';
import NorthMyrtleArea_48 from '../assets/image/NorthMyrtleArea/northmyrtlearea_48.jpg';
import NorthMyrtleArea_49 from '../assets/image/NorthMyrtleArea/northmyrtlearea_49.jpg';
import NorthMyrtleArea_50 from '../assets/image/NorthMyrtleArea/northmyrtlearea_50.jpg';
import NorthMyrtleArea_51 from '../assets/image/NorthMyrtleArea/northmyrtlearea_51.jpg';
import NorthMyrtleArea_52 from '../assets/image/NorthMyrtleArea/northmyrtlearea_52.jpg';

function NorthMyrtleArea() {

    return (
        <div className="main_div">
            <Header />
            <div className="page-header-with-back-image-title-subtitle even-padding" style={{ backgroundImage: `url("${NorthMyrtleArea_7}")` }}>
                <Container>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <div className="page-header-with-back-image-title-subtitle-inner text-center">
                                <h1>North Myrtle Area</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="wilddunesgallery">
                <Container>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_1} image={NorthMyrtleArea_1} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_2} image={NorthMyrtleArea_2} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_3} image={NorthMyrtleArea_3} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_4} image={NorthMyrtleArea_4} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_5} image={NorthMyrtleArea_5} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_6} image={NorthMyrtleArea_6} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_7} image={NorthMyrtleArea_7} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_8} image={NorthMyrtleArea_8} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_9} image={NorthMyrtleArea_9} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_10} image={NorthMyrtleArea_10} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_11} image={NorthMyrtleArea_11} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_12} image={NorthMyrtleArea_12} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_13} image={NorthMyrtleArea_13} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_14} image={NorthMyrtleArea_14} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_15} image={NorthMyrtleArea_15} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_16} image={NorthMyrtleArea_16} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_17} image={NorthMyrtleArea_17} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_18} image={NorthMyrtleArea_18} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_19} image={NorthMyrtleArea_19} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_20} image={NorthMyrtleArea_20} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_21} image={NorthMyrtleArea_21} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_22} image={NorthMyrtleArea_22} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_23} image={NorthMyrtleArea_23} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_24} image={NorthMyrtleArea_24} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_25} image={NorthMyrtleArea_25} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_26} image={NorthMyrtleArea_26} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_27} image={NorthMyrtleArea_27} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_28} image={NorthMyrtleArea_28} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_29} image={NorthMyrtleArea_29} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_30} image={NorthMyrtleArea_30} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_31} image={NorthMyrtleArea_31} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_32} image={NorthMyrtleArea_32} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_33} image={NorthMyrtleArea_33} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_34} image={NorthMyrtleArea_34} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_35} image={NorthMyrtleArea_35} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_36} image={NorthMyrtleArea_36} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_37} image={NorthMyrtleArea_37} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_38} image={NorthMyrtleArea_38} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_39} image={NorthMyrtleArea_39} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_40} image={NorthMyrtleArea_40} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_41} image={NorthMyrtleArea_41} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_42} image={NorthMyrtleArea_42} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_43} image={NorthMyrtleArea_43} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_44} image={NorthMyrtleArea_44} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_45} image={NorthMyrtleArea_45} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_46} image={NorthMyrtleArea_46} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_47} image={NorthMyrtleArea_47} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_48} image={NorthMyrtleArea_48} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_49} image={NorthMyrtleArea_49} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_50} image={NorthMyrtleArea_50} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_51} image={NorthMyrtleArea_51} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={NorthMyrtleArea_52} image={NorthMyrtleArea_52} /></div>
                </Container>
            </div>
            <div className="wilddunesvideo">
                <Container>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <iframe title="Wild Dunes Branded SC Beach Vacations" width="100%" height="686" src="https://www.youtube.com/embed/xdkU0FGQyAo?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    );
}

export default NorthMyrtleArea;