import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../common/footer";
import Header from "../common/header";

import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LakeLanierWesternStyleLogHome_1 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_1.jpg';
import LakeLanierWesternStyleLogHome_2 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_2.jpg';
import LakeLanierWesternStyleLogHome_3 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_3.jpg';
import LakeLanierWesternStyleLogHome_4 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_4.jpg';
import LakeLanierWesternStyleLogHome_5 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_5.jpg';
import LakeLanierWesternStyleLogHome_6 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_6.jpg';
import LakeLanierWesternStyleLogHome_7 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_7.jpg';
import LakeLanierWesternStyleLogHome_8 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_8.jpg';
import LakeLanierWesternStyleLogHome_9 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_9.jpg';
import LakeLanierWesternStyleLogHome_10 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_10.jpg';
import LakeLanierWesternStyleLogHome_11 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_11.jpg';
import LakeLanierWesternStyleLogHome_12 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_12.jpg';
import LakeLanierWesternStyleLogHome_13 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_13.jpg';
import LakeLanierWesternStyleLogHome_14 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_14.jpg';
import LakeLanierWesternStyleLogHome_15 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_15.jpg';
import LakeLanierWesternStyleLogHome_16 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_16.jpg';
import LakeLanierWesternStyleLogHome_17 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_17.jpg';
import LakeLanierWesternStyleLogHome_18 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_18.jpg';
import LakeLanierWesternStyleLogHome_19 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_19.jpg';
import LakeLanierWesternStyleLogHome_20 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_20.jpg';
import LakeLanierWesternStyleLogHome_21 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_21.jpg';
import LakeLanierWesternStyleLogHome_22 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_22.jpg';
import LakeLanierWesternStyleLogHome_23 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_23.jpg';
import LakeLanierWesternStyleLogHome_24 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_24.jpg';
import LakeLanierWesternStyleLogHome_25 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_25.jpg';
import LakeLanierWesternStyleLogHome_26 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_26.jpg';
import LakeLanierWesternStyleLogHome_27 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_27.jpg';
import LakeLanierWesternStyleLogHome_28 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_28.jpg';
import LakeLanierWesternStyleLogHome_29 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_29.jpg';
import LakeLanierWesternStyleLogHome_30 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_30.jpg';
import LakeLanierWesternStyleLogHome_31 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_31.jpg';
import LakeLanierWesternStyleLogHome_32 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_32.jpg';
import LakeLanierWesternStyleLogHome_33 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_33.jpg';
import LakeLanierWesternStyleLogHome_34 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_34.jpg';
import LakeLanierWesternStyleLogHome_35 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_35.jpg';
import LakeLanierWesternStyleLogHome_36 from '../assets/image/LakeLanierWesternStyleLogHome/Lake_Lanier_Western_Style_Log_Home_36.jpg';

function LakeLanierWesternStyleLogHome() {


    var settings = {
        dots: true,
        //infinite: false,
        arrows: false,
        infinite: true,
        //speed: 5000,
        autoplaySpeed: 5000,
        //autoplay: true,
        autoplay: false,
        adaptiveHeight: false,
        slidesToShow: 1,
        pauseOnFocus: true,
        cssEase: 'ease',
        fade: true,
        pauseOnHover: true,
        swipeToSlide: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="main_div">
            <Header />
            <div className="page-header-with-back-image-title-subtitle" style={{ backgroundImage: `url("${LakeLanierWesternStyleLogHome_1}")` }} >
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <div className="page-header-with-back-image-title-subtitle-inner">
                                <h1>Lake Lanier Western Style Log Home</h1>
                                <h3>START FROM <span>$600</span> <sub>/ NIGHT</sub></h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-properties-gallery-outer">
                <Container>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_1} image={LakeLanierWesternStyleLogHome_1} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_2} image={LakeLanierWesternStyleLogHome_2} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_3} image={LakeLanierWesternStyleLogHome_3} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_4} image={LakeLanierWesternStyleLogHome_4} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_5} image={LakeLanierWesternStyleLogHome_5} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_6} image={LakeLanierWesternStyleLogHome_6} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_7} image={LakeLanierWesternStyleLogHome_7} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_8} image={LakeLanierWesternStyleLogHome_8} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_9} image={LakeLanierWesternStyleLogHome_9} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_10} image={LakeLanierWesternStyleLogHome_10} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_11} image={LakeLanierWesternStyleLogHome_11} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_12} image={LakeLanierWesternStyleLogHome_12} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_13} image={LakeLanierWesternStyleLogHome_13} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_14} image={LakeLanierWesternStyleLogHome_14} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_15} image={LakeLanierWesternStyleLogHome_15} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_16} image={LakeLanierWesternStyleLogHome_16} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_17} image={LakeLanierWesternStyleLogHome_17} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_18} image={LakeLanierWesternStyleLogHome_18} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_19} image={LakeLanierWesternStyleLogHome_19} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_20} image={LakeLanierWesternStyleLogHome_20} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_21} image={LakeLanierWesternStyleLogHome_21} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_22} image={LakeLanierWesternStyleLogHome_22} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_23} image={LakeLanierWesternStyleLogHome_23} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_24} image={LakeLanierWesternStyleLogHome_24} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_25} image={LakeLanierWesternStyleLogHome_25} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_26} image={LakeLanierWesternStyleLogHome_26} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_27} image={LakeLanierWesternStyleLogHome_27} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_28} image={LakeLanierWesternStyleLogHome_28} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_29} image={LakeLanierWesternStyleLogHome_29} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_30} image={LakeLanierWesternStyleLogHome_30} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_31} image={LakeLanierWesternStyleLogHome_31} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_32} image={LakeLanierWesternStyleLogHome_32} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_33} image={LakeLanierWesternStyleLogHome_33} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_34} image={LakeLanierWesternStyleLogHome_34} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_35} image={LakeLanierWesternStyleLogHome_35} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={LakeLanierWesternStyleLogHome_36} image={LakeLanierWesternStyleLogHome_36} /></div>
                </Container>
            </div>
            <div className="single-property-description">
                <div className="single-property-description-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-title-inner"><h4>Description Of Properties</h4></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-description-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-content-inner">
                                    <p>Luxurious Lake Lanier Cabin. Searching for the perfect vacation spot? Look no further than this luxurious Western style log home. This place is perfect for family, friends, or small work retreats. It has plenty of space with a total of five bedrooms, five bathrooms, and spacious kitchen so everyone will be comfortable. There is also a spacious living room with a gorgeous view of the lake. There are also three indoor and one outdoor fireplaces where you can warm up on chillier nights.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="single-property-amenities">
                <div className="single-property-amenities-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-amenities-title-inner mb-4"><h4>Highlights</h4></div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <div className="single-property-amenities-content-inner-content">
                            <Row>
                                <Col lg={4} md={4} sm={12}><ul><li>Property Type: Entire Home</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-in: 3:00 PM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-out: 12:00 AM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Maximum Number Of Guests: 12</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Minimum Age Of Primary Renter: 25</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Children Welcome</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Pets Not Allowed</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Parties/Events NOT ALLOWED</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Non Smoking Only</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Long-term stays (28 days or more) are allowed</li></ul></Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="single-property-amenities-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities Unit 403</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <Row>
                                            <Col lg={4} md={4} sm={12}><ul><li>4 Bedrooms, Sleeps 12</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>4.5 Bathrooms</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>4 King Sized Bed, 1 Queen Sized Bed</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Kitchen</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Hot tub</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Free parking on premises</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Wifi</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Cable TV</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Indoor fireplace</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Dedicated workspace</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Dryer</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>TV</li></ul></Col>
                                            <Col lg={4} md={4} sm={12}><ul><li>Hangers</li></ul></Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
            <div className="single-property-testimonials">
                <div className="single-property-testimonials-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-title-inner">
                                    <h4>Testimonials</h4>
                                    <p>What People Say About Us</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-testimonials-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-content-inner">
                                    <div className="single-property-testimonials-slider">
                                        <Slider className="single-property-testimonials-slider" {...settings} >
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Great house in a great location. It was perfect for our 8-person family group. The house is very spacious (bigger than it looks in the pictures) and has lots of great amenities. Highly recommended!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Rich</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>This home is a delightful place. So peaceful and relaxing. Perfect for families to unwind and spend time together away from the noise. Everything was amazing. The kids are already asking when we can come back. Thanks for sharing your home!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Casey</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>House was great. We had lots of fun playing on the Kayaks and enjoying the dock. The hot tub was awesome and the theater room is incredible. We had a outstanding time!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Josh</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>We spent a wonderful weekend in this beautiful home celebrating a 40th birthday. The home was very spacious and had everything we needed. The dock was nice to have to swim and fish. The theater room was a great perk too! Overall it was a wonderful time and we hope to return.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Jessica</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>This log cabin was really a terrific stay. Incredibly spacious with thoughtful interior design. It felt very cozy from the time we arrived. Movie theater, pool table, lake access. The perfect recipe for a secluded stay on the lake spending time with family or friends.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Ryan</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Peter’s place has a very good location. It’s right next to the lake. There were tons of utensils in the kitchen. We ended up not using any of the utensils that we brought. The projector and the sound system in the movie theater were very impressive. We had a good vacation at Peter’s place.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Xinyi</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>This cabin is quite spacious and the kitchen is well stocked except for a hand mixer. I brought games for our family to play in the evenings but did not use any of them because of your pool table and theater room. Thank you for offering your cabin for our annual family get-together.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Pamela</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default LakeLanierWesternStyleLogHome;